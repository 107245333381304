/* AboutBanner Css started */

.Common_Banner .about-banner {
    height: 100%;
    width: 100%;
}

.Common_Banner{
    position: relative;
}
.Common_Banner .content-text-holder {
    position: absolute;
    top: 0px;
}

/* AboutBanner Css End */


@media (min-width:0px) and (max-width:485px) {
    .Common_Banner {
      
        aspect-ratio: 3/1;
    }
}