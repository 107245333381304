
/* SimilarProduct Css started */

.SimilarProduct h1.product-detail {
    text-align: center;
    margin-bottom: 3%;
}
.SimilarProduct .Main-similar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 11px #00000029;
    padding: 2% 3%;
}
.SimilarProduct .swiper-pagination-bullet {
    height: 6px !important;
    border-radius: 4px !important;
    width: 13px !important;
    border-radius: 10px !important;
    opacity: 1;
    background-color: #5b4020;
}
.SimilarProduct span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 6px !important;
    border-radius: 10px !important;
    width: 26px !important;
    background-color: #24642e !important;
}
.SimilarProduct .swiper-pagination-fraction,
.SimilarProduct .swiper-pagination-custom,
.SimilarProduct .swiper-horizontal > .swiper-pagination-bullets,
.SimilarProduct .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 4px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}
.SimilarProduct .swiper-pagination-lock {
    display: block;
}
.SimilarProduct .Start button.start-main {
    border: none;
   
    padding: 8px 36px;
    font-weight: bold;
}
.SimilarProduct  .Start {
    text-align: center;
    margin-top: 3%;
}
section.SimilarProduct {
    margin-bottom: 9%;
}
/* SimilarProduct Css End */