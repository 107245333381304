.IncreamentDecreament .minus {
  margin: 0px 3px;
  padding: 0px;
  border: none;
  font-weight: bold;
  font-size: 20px;
  background-color: transparent;
}
.IncreamentDecreament .add {
  font-weight: bold;
  font-size: 20px;
  margin: 0px 3px;
  padding: 0px;
  border: none;
  /* border-radius: 0px; */
  background-color: transparent;
}
.IncreamentDecreament .minus.active {
  border: none;
  border-radius: none;
}
.IncreamentDecreament .add.active {
  border: none;
  border-radius: none;
}
.IncreamentDecreament .shopping-img-align {
  margin-right: 11px;
}
.IncreamentDecreament  .quantity {
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 12px;
  /* background-color: #d0cfcf; */
  padding: 2px 16px;
}
.IncreamentDecreament .add-cart-box {
  margin: 0px 14px;
  display: flex;
  align-items: center;
}

.IncreamentDecreament .Main-Buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
}
