/* CartPage Css Started */

.CartPage .main-cart {
    background: #fff6f6 0% 0% no-repeat padding-box;
    border-radius: 7px;
    width: 100%;
    padding: 2% 4%;
    margin-top: 3%;
}
.CartPage .main-cart .form-check-input[type="checkbox"] {
    border: 1px solid #24642e;
    font-size: 20px;
    border-radius: 0px;
}
.CartPage {
    margin-top: 2%;
    margin-bottom: 6%;
}
.CartPage .main-cart .form-check-input:checked {
    font-size: 20px;
    background-color: transparent !important;
    border-color: #24642e !important;
    border-radius: 0px;
}
.CartPage .main-cart .form-check-input:checked[type="checkbox"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%2324642E%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
.CartPage .main-cart label.form-check-label {
    color: #222222;
    font-size: 18px;
}
.CartPage .Main-Table th.table-h1 {
    border: 1px solid #dddddd;
    color: #222222;
    font-weight: 500;
    padding: 17px 5px !important;
    background-color: #f5f5f5 !important;
    text-align: center;
}
.CartPage .Main-Table .image-cart img.product {
    height: 100%;
    width: 100%;
    border-radius: 11px;
}
.CartPage .Main-Table .image-cart {
    width: 160px;
    padding: 6px 0px;
    height: 88px;
}
.CartPage .Main-Table td.main-data {
    text-align: -webkit-center;
    background-color: #ffffff;
}
.CartPage .IncreamentDecreament .add-cart-box {
    justify-content: center;
}
.CartPage .Main-Table .product {
    width: 160px !important;
}
.CartPage .Main-Table .product-name {
    width: 160px !important;
}
.CartPage .Main-Table .size {
    width: 140px !important;
}
.CartPage .Main-Table .packs {
    width: 100px !important;
}
.CartPage .Main-Table .amount {
    width: 150px !important;
}
.CartPage .Main-Table .delete {
    width: 80px !important;
}
.CartPage .Main-Table button.delete {
    border: none;
}
.CartPage .Main-Table .icon {
    font-size: 11px;
    color: #878181;
}
.CartPage .Main-Table .delete-btn {
    border-radius: 100px;
    width: 30px;
    height: 30px;
    background-color: #f5f5f5;
    padding: 3px 0px;
    cursor: pointer;
}
.CartPage .IncreamentDecreament .quantity {
    background: transparent !important;
    border-radius: 5px;
    font-size: 14px;
    /* background-color: #d0cfcf; */
    padding: 2px 16px;
}

/* order Summary */
.CartPage .Order-Summary {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 27px 13px 30px 13px;
}
.CartPage .Order-Summary h1.sub-text {
    color: #24642e;
    font-weight: 500;
    text-align: center;
}
.CartPage .Order-Summary .Total {
    margin-top: 3%;
    display: flex;
    justify-content: space-between;
}
.CartPage .Order-Summary .total-payment .total-main {
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
}
.CartPage .Order-Summary .main {
    border-bottom: 1px solid rgb(192, 192, 192);
}
.CartPage .Order-Summary .total-payment .total-main .sub-text {
    color: #24642e;
}
.CartPage .Order-Summary button.check {
    color: white;
    border: none;
    /* background: #56a462 0% 0% no-repeat padding-box;
    border-radius: 5px; */
    padding: 7px 40px;
}

.CartPage .Order-Summary .Proceed {
    text-align: center;
    margin-top: 6%;
}
.CartPage .table-h1 .check{
background-color: transparent !important;
border:  none !important;
}
.CartPage td.main-data.table-bordered {
    border: 1px solid #e3e3e3;
    vertical-align: baseline;
}
.CartPage .Main-Table .table-responsive .table td.check {
    vertical-align: baseline;
}
/* Media Query started */

@media (min-width: 0px) and (max-width: 767px) {
    .CartPage .Main-Table .size {
        width: 197px !important;
    }
}
