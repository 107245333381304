.ProductContent .title {
    color: #272525;
    font-weight: 100;
    margin-bottom: 4%;
}
section.ProductContent {
    margin-top: 3%;
}
.ProductContent .Mai-Content {
    background-color: #F6F6F6;
}
.ProductContent .nav-pills .nav-link.active  {
    padding: 12px 33px;
    border-radius: 0px;
    background-color: #ffffff;
}
.ProductContent .nav-pills .nav-link{
    padding: 12px 33px;
    border-radius: 0px;
}
.ProductContent .price-text {
    margin-bottom: 0px;
    border-bottom: 1px solid grey;
}
.ProductContent .Mai-Content .tab-content {
    padding: 1% 1%;
}
.ProductContent .Mai-Content .tab-content .mini-text {
    line-height: 24px;
}



/* *********************Media Query started ******************/

@media (min-width: 0px) and (max-width: 485px) {
    section.ProductContent {
        margin-top: 8%;
    }
    .nav.nav-pills {
        overflow: auto;
        flex-wrap: nowrap;
    }
    .ProductContent .nav-pills .nav-link.active {
        padding: 9px 24px;
    }
    .ProductContent .nav-pills .nav-link {
        width: 200px;
        padding: 8px 29px;
        border-radius: 0px;
    }
    .ProductContent .mini-text {
       text-align: justify;
    }
}