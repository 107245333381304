.cont_sectionn {
    position: relative;
    background-image: url("../../../public/assests/ContactUs/contact-us.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-bottom: 3%;
    padding-top: 2%;
    height: 100%;
    margin-bottom: 8%;
}

.cont_sectionn .contact_section .fonticndiv {
    width: 35px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 50%;
    color: #a6a6a6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont_sectionn .contact_section .leftdivv {
    height: 100%;
    margin-top: 4%;
    width: 100%;
    background-color: #fff;
    padding: 30px 70px 20px 70px;
}
.cont_sectionn .contact_section .leftdivv1 {
    height: 100%;
    width: 100%;
    padding: 50px 15px 151px 15px;
    background-color: #ab755c;
    margin-top: 8%;
}

.cont_sectionn .contact_section .btnnnn {
    background: #24642e 0% 0% no-repeat padding-box;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 9px 15px;
    font-size: 15px;
    font-family: Nunito Sans;
}
.cont_sectionn .contact_section .btndiv {
    padding-top: 7px;
}

.cont_sectionn .contact_section .contactus {
    font-size: 70px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #202020;
}

.cont_sectionn .contact_section .cont_text {
    font-size: 13px;
    font-weight: 300;
    color: #ffffff;
    font-family: "Montserrat";
}

.cont_sectionn .contact_section .contact_section {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0px;
}

.cont_sectionn .contact_section input#exampleForm\.ControlInput1 {
    border: none;
    border-bottom: 2px solid #b3b3b3;
    border-radius: 0px;
    font-size: 15px;
    color: #7b7b7b;
    font-family: Nunito Sans;
}

.cont_sectionn .contact_section textarea#exampleForm\.ControlTextarea1 {
    border: none;
    border-bottom: 2px solid #b3b3b3;
    border-radius: 0px;
    font-size: 15px;
    color: #7b7b7b;
    font-family: Nunito Sans;
}

.cont_sectionn .contact_section .react-tel-input .form-control {
    border: none;
    border-bottom: 2px solid #b3b3b3;
    border-radius: 0px;
    width: 100%;
    color: #7b7b7b;
    font-family: Nunito Sans;
}

.cont_sectionn .contact_section .react-tel-input .form-control:focus {
    background-color: #fff;

    box-shadow: none;
}
.cont_sectionn .contact_section .react-tel-input .flag-dropdown {
    background-color: #ffff;
    border: none;
    border-bottom: 3px solid #b3b3b3;
    border-radius: 0px;
}
.cont_sectionn iframe.gmap_iframe {
    width: 100%;
    height: 100%;
}

.cont_sectionn .gmap_canvas {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 0;

    transition: height 0.4s ease-in-out;
}

.cont_sectionn .gmap_canvas.show {
    height: 100% !important;
}

.cont_sectionn .angleee {
    position: absolute;
    width: 100%;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    height: 129px;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont_sectionn .angleicnss {
    font-size: 40px;
}

@media (min-width: 0px) and (max-width: 485px) {
    .cont_sectionn .contact_section .leftdivv {
        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn .contact_section .btndiv {
        padding-top: 30px;
    }

    .cont_sectionn .contact_section .contactus {
        font-size: 30px;
    }
    .cont_sectionn .angleicnss {
        font-size: 20px;
    }
    .cont_sectionn .sent-successfull .success-lottie {
        position: unset;
    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .cont_sectionn .contact_section .leftdivv {
        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn .contact_section .btndiv {
        padding-top: 30px;
    }

    .cont_sectionn .contact_section .contactus {
        font-size: 40px;
    }
    .cont_sectionn .angleicnss {
        font-size: 24px;
    }
    .cont_sectionn .contact_section .leftdivv1 {
        margin-top: 6%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .cont_sectionn .contact_section .leftdivv {
        margin-left: -16px;
        padding: 30px 20px 20px 20px;
    }

    .cont_sectionn .contact_section .contactus {
        font-size: 50px;
    }
    .cont_sectionn .angleicnss {
        font-size: 28px;
    }
    .cont_sectionn .contact_section .leftdivv1 {
        margin-top: 6%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .cont_sectionn .contact_section .leftdivv1 {
        margin-top: 6%;
    }
}

.cont_sectionn .react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -2px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 6px solid #555 !important;
}
.cont_sectionn .title {
    color: #2b2b2b;
    font-weight: 100;
}
.cont_sectionn .icon {
    color: #ab755c;
}
.cont_sectionn .information {
    margin-bottom: 30px;
}
.cont_sectionn .form-check-input:checked {
    font-size: 20px;
    background-color: #000000 !important;
    border-color: #000000 !important;
    border-radius: 0px;
}
.cont_sectionn .form-check-input[type="checkbox"] {
    font-size: 20px;
}
.cont_sectionn label.form-check-label {
    margin: 6px 0px;
    font-size: 14px;
}
.cont_sectionn form.check {
    margin: 0px 12px;
    padding-top: 5%;
}
.cont_sectionn .map {
    height: 453px;
    transform: rotateY(-180deg);
}
.cont_sectionn .sent-successfull .success-lottie {
    position: absolute;
    top: -14px;
}
.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.cont_sectionn .sent-successfull {
    left: 0px;
    transform: translate(0%, -50%);
    top: 50%;
    position: relative;
    text-align: end;
    padding: 20px 27px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}
.Information-client {
    margin-top: 20px;
}
@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}
@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}

/* Flip animation keyframes */
@-webkit-keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}

@keyframes flip-vertical-right {
    0% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }
    100% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
}

.cont_sectionn .flip-vertical-right {
    transition: all 0.5 ease-in-out;
    -webkit-animation: flip-vertical-right 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    animation: flip-vertical-right 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.cont_sectionn .hidden {
    display: none;
}

.cont_sectionn .map.fullscreen {
    position: relative;
    width: 100%;
    height: 100vh;
    margin-top: 20px;
    animation: flip-vertical-right 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both reverse;
}

.cont_sectionn .map {
    position: relative;
    width: 100%;
    height: 500px;
    margin-top: 20px;
    animation: flip-vertical-right 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both reverse;
}
.cont_sectionn .map .close {
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 15px;
}
.cont_sectionn .map .expand {
    margin: 0px 20px;
}
.cont_sectionn .map .close-icon {
    cursor: pointer;
}
.cont_sectionn .map .cursor-pointer {
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 991px) {
    .cont_sectionn .sent-successfull .success-lottie {
        top: 11px;
        position: unset;
    }
    .cont_sectionn .sent-successfull {
        text-align: center;
    }
}
