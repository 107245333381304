.Change .mid-title {
    text-align: center;
    margin-top: 3%;
}
.Change button.SubmitBtn {
    padding: 8px 76px;
    font-size: 13px;
    border: none;
}
.Change button.YesBtn.brown {
    border: none;
    padding: 8px 29px;
    color: white;
}
.Change .btn-holder.yes_no_btn_holder {
    text-align: center;
    margin-top: 2%;
}

@media (min-width: 0px) and (max-width: 320px) {
    .Change button.SubmitBtn {
        padding: 8px 16px;
        font-size: 11px;
    }
    .Change button.YesBtn.brown {
        font-size: 11px;
        padding: 8px 12px;
    }
    .Change nav {
        margin: 13px 0px;
    }
    .Change .btn-holder.yes_no_btn_holder {
      text-align: center;
      margin-top: 4%;
  }
}
@media (min-width: 321px) and (max-width: 485px) {
  .Change button.SubmitBtn {
    padding: 8px 22px;
    font-size: 11px;
}
  .Change button.YesBtn.brown {
    font-size: 11px;
    padding: 8px 15px;
}
  .Change nav {
      margin: 13px 0px;
  }
  .Change .btn-holder.yes_no_btn_holder {
    text-align: center;
    margin-top: 4%;
}
}

