.FAq-Tab-Section {
    margin-bottom: 15%;
}

.FAq-Tab-Section .faq_main_div {
    position: relative;
    padding-top: 15px;
}

.FAq-Tab-Section .tab_section {
    position: absolute;
    width: 100%;
    left: 0;
    top: -80px;
}

.FAq-Tab-Section .tab-content {
    padding: 2% 0%;
}

.FAq-Tab-Section .faqconts {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding-top: 45px;
    padding: 45px 0px 30px 50px;
    margin-top: -6%;
    background: #fff;
    /* height: 500px; */
}

.FAq-Tab-Section .tabitemss {
    /* border: 1px solid #a6a6a6; */
    border-radius: 7px;
}

.FAq-Tab-Section .tab_section {
    padding-left: 65px;
}
.FAq-Tab-Section .accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: none;
    font-size: 18px;
    font-weight: bold;
}

.FAq-Tab-Section .faq_heading {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.FAq-Tab-Section .faq_text {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    font-family: Nunito Sans;
}

.FAq-Tab-Section .accordion-button {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.FAq-Tab-Section li {
    list-style: none;
}

.FAq-Tab-Section .accordion-item {
    border: none;
}

.FAq-Tab-Section .accordheader {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
}

.FAq-Tab-Section .accordion-button:focus {
    box-shadow: none;
}

.FAq-Tab-Section .nav-pills .nav-link {
    font-size: 15px !important;
    font-weight: 500;
    background: #fff;
    padding: 12px 49px;
    border: 1px solid #a6a6a6;
}

.FAq-Tab-Section .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    padding: 12px 49px;
    color: #fff !important;
    background: #202020;
    border-radius: 5px;
    border: 1px solid #202020;
    /* font-size: 15px !important; */
}
.FAq-Tab-Section .accordion-button:not(.collapsed)::after {
    filter: hue-rotate(211deg);
}

@media (min-width: 0px) and (max-width: 485px) {
    .FAq-Tab-Section {
        margin-bottom: 50px;
    }
    .FAq-Tab-Section .faqconts {
        padding: 87px 0px 17px 1px;
    }
    .FAq-Tab-Section .accordion-button {
        font-size: 15px;
    }
    .FAq-Tab-Section .nav-pills .nav-link {
        font-size: 13px !important;
    }
    .FAq-Tab-Section .faqconts {
        margin-top: 8%;
    }
    .FAq-Tab-Section .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        padding: 11px 14px;
        font-size: 13px;
        text-align: center;
    }
    .FAq-Tab-Section .nav-pills .nav-link {
        padding: 11px 14px;
        width: 173px;
        text-align: center;
    }
    .FAq-Tab-Section .tab_section {
        top: 46px;
    }
    .FAq-Tab-Section .accordion-button:not(.collapsed) {
        font-size: 16px;
    }
    .FAq-Tab-Section .nav.nav-pills {
        overflow: auto;
        flex-wrap: nowrap;
    }
    .FAq-Tab-Section .tab_section {
        padding: 12px 14px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .FAq-Tab-Section {
        margin-bottom: 65px;
    }

    .FAq-Tab-Section .accordion-button {
        font-size: 16px;
    }

    .FAq-Tab-Section .faqconts {
        margin-top: 8%;
    }

    .FAq-Tab-Section .nav-pills .nav-link {
        padding: 11px 14px;
    }
    .FAq-Tab-Section .tab_section {
        top: 46px;
    }
    .FAq-Tab-Section .accordion-button:not(.collapsed) {
        font-size: 16px;
    }
    .FAq-Tab-Section .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        padding: 11px 14px;
        font-size: 13px !important;
        text-align: center;
    }
    .FAq-Tab-Section .nav-pills .nav-link {
        padding: 11px 14px;
        font-size: 13px !important;
        width: 173px;
        text-align: center;
    }
    .FAq-Tab-Section .nav.nav-pills {
        overflow: auto;
        flex-wrap: nowrap;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .FAq-Tab-Section {
        margin-bottom: 80px;
    }

    .FAq-Tab-Section .accordion-button {
        font-size: 17px;
    }
    .FAq-Tab-Section .faqconts {
        margin-top: 8%;
    }
    .FAq-Tab-Section .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        padding: 11px 14px;
        font-size: 14px;
    }
    .FAq-Tab-Section .nav-pills .nav-link {
        padding: 11px 14px;
        font-size: 14px;
    }
    .FAq-Tab-Section .tab_section {
        top: 46px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .FAq-Tab-Section .faq_main_div {
        padding-top: 164px;
    }
    .FAq-Tab-Section .tab_section {
        top: 74px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .FAq-Tab-Section {
        margin-bottom: 80px;
    }
}
