/* ProductDetailsPage Css Started */

.ProductDetailsPage .Image-product img.product-detail {
    width: 100%;
    height: 100%;
}
.ProductDetailsPage .Product_detail-Content .Quantity {
    margin-top: 3%;
    padding: 35px 74px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
}
.ProductDetailsPage .Product_detail-Content .Quantity .dashed {
    border-bottom: dashed 1px grey;
    margin: 8px 0px;
    text-align: center;
    width: 79px;
}
.ProductDetailsPage .Product_detail-Content {
    margin-left: 3%;
}
.ProductDetailsPage .Product_detail-Content .Quantity .table .text {
    color: black;
}
.table > :not(caption) > * > * {
    border-bottom-width: inherit !important;
}
.ProductDetailsPage .Product_detail-Content .Quantity .main {
    color: white;
}

.ProductDetailsPage .Product_detail-Content .Quantity table.table {
    width: 100%;
}
.ProductDetailsPage .Image-product .heart {
    height: 26px;
    width: 26px;
    padding: 1px 3px;
    border-radius: 100px;
    background-color: white;
    font-size: 19px;
    position: absolute;
    top: 18px;
    right: 24px;
    box-shadow: 0px 0px 6px #00000029;
}
.ProductDetailsPage .Image-product {
    position: relative;
}
.ProductDetailsPage .Product_detail-Content .cart-btn button.sample {
    margin-right: 20px;
    font-size: 14px;
    color: white;
    border: none;
    padding: 8px 16px;
    /* background: #56a462 0% 0% no-repeat padding-box;
    border-radius: 5px; */
    font-weight: bold;
    font-family: Nunito Sans;
}
.ProductDetailsPage svg.svg-inline--fa.fa-cart-shopping.heart-icon{
    margin: 0px 6px;
}
.ProductDetailsPage .Product_detail-Content .cart-btn button.cart {
    font-size: 14px;
    color: white;
    border: none;
    padding: 9px 20px;
    /* background: #ab755c 0% 0% no-repeat padding-box;
    border-radius: 5px; */
    font-weight: bold;
    font-family: Nunito Sans;
}
.ProductDetailsPage .Product_detail-Content .Quantity .mini-text {
    margin-top: 4%;
    color: #838282;
}
.ProductDetailsPage .Product_detail-Content .Quantity .text {
    cursor: pointer;
    margin: 5px 0px;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 1px solid green;
    color: green;
    /* margin-bottom: 5px; */
}
.ProductDetailsPage .Product_detail-Content .Quantity .main-text {
    margin: 0px 30px;
}
/* ProductDetailsPage Css End */

/* *********************Media Query started ******************/

@media (min-width: 991px) and (max-width: 1199px) {
    .ProductDetailsPage .Product_detail-Content .Quantity {
        padding: 24px 40px;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.sample {
        font-size: 12px;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.sample {
        font-size: 12px;
    }
    th.pack {
        font-size: 14px;
    }
    .ProductDetailsPage .Product_detail-Content {
        margin-left: 0%;
        margin-top: 2%;
    }
}
@media (min-width: 0px) and (max-width: 485px) {
    .ProductDetailsPage table.table.table-responsive {
        width: 100%;
        border-collapse: collapse;
        min-width: 481px;
    }
    .ProductDetailsPage .Price {
        width: 100%;
        overflow-x: auto;
        padding: 20px;
    }
    .ProductDetailsPage .Product_detail-Content .Quantity {
        padding: 6px 11px;
    }
    .ProductDetailsPage .Product_detail-Content {
        margin-top: 5%;
        margin-left: 0%;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.sample {
        font-size: 11px;
        padding: 8px 12px;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.cart {
        font-size: 11px;
        padding: 8px 37px;
        margin: 6px 0px;
    }
    th.pack {
        font-size: 14px;
    }
    .ProductDetailsPage .Product_detail-Content .Quantity .dashed {
        width: 41px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .ProductDetailsPage table.table.table-responsive {
        width: 100%;
        border-collapse: collapse;
        min-width: 481px;
    }
    .ProductDetailsPage .Price {
        width: 100%;
        overflow-x: auto;
        padding: 20px;
    }
    .ProductDetailsPage .Product_detail-Content .Quantity {
        padding: 6px 11px;
    }
    .ProductDetailsPage .Product_detail-Content {
        margin-top: 5%;
        margin-left: 0%;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.sample {
        font-size: 13px;
        padding: 9px 12px;
    }
    .ProductDetailsPage .Product_detail-Content .cart-btn button.cart {
        font-size: 12px;
        padding: 10px 37px;
        margin: 6px 0px;
    }
    th.pack {
        font-size: 14px;
    }
    .ProductDetailsPage .Product_detail-Content .Quantity .dashed {
        width: 41px;
    }
    .ProductDetailsPage .Product_detail-Content .Quantity .main-text {
        margin: 0px 5px;
    }
    .ProductContent .Mai-Content .tab-content .mini-text {
       
        text-align: justify;
    }
}
