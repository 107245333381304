.faq img.banner {
    width: 100%;
    height: 100%;
}
.faq .Main-Faq {
    position: relative;
}
.faq .Content {
    position: absolute;
    top: 0%;
}