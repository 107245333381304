/* QualityProducts css started */

.QualityProducts .QualityProducts-Main {
    padding: 40px 0px;
    background: #24642E 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029, 0px 3px 3px #00000014;
    border-radius: 10px;
    /* height: 100px; */
}
.QualityProducts .sub-text {
    margin-bottom: 6px;
}
.QualityProducts .mini-text {
    margin-bottom: 0px;
  
}
.QualityProducts .product {
    display: flex;
    align-items: center;
}
.QualityProducts .Content {
    margin: 0px 25px;
}
.QualityProducts .icon {
 height: 44px;
color: #F2F200;
}
.QualityProducts .content-Products {
    display: flex;
    justify-content: space-around;
}
.QualityProducts .mini-text {
    font-size: 15px;
    color: #f8efef;
    opacity: 1;
    font-family: "Nunito Sans";
}
.QualityProducts .border-main {
    margin: 0px 0px;
    border-right: 1px solid white;
}
section.QualityProducts {
    margin-top: 2%;
    margin-bottom: 3%;
}

@media (min-width:0px) and (max-width:991px) {
    .QualityProducts .content-Products {
        display: grid;
       
    }
    .QualityProducts .product {
      
        margin: 14px 0px;
    }
    .QualityProducts .QualityProducts-Main {
        padding: 23px 13px;
       
    }
    .QualityProducts .icon {
        height: 37px;
        color: #F2F200;
    }
    .QualityProducts .Content {
        margin: 0px 18px;
    }
}