/* Banner Css Started */

.Home-Banner .swiper {
    width: 100%;
    height: 100%;
}

.Home-Banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Banner .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
}
.Home-Banner .image-home img.banner-image {
    width: 100%;
    height: 100%;
    position: relative;
}
.Home-Banner .swiper-button-prev:after,
.swiper-button-next:after {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
}
.Home-Banner .image-overlay {
    width: 100%;
    height: 115px;
    background: transparent linear-gradient(180deg, #cfcfcfd9 0%, #a9a5a526 61%, #efeeed00 81%, #ffffff00 92%, #cf420000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: -18px;
}
.Home-Banner .swiper-button-prev:after,
.swiper-button-next:after {
    color: #ffffff !important;
    font-size: 30px !important;
    font-weight: 600 !important;
}
.Home-Banner .image-home .content-banner .main-content {
    padding: 28px 165px;
    background: #0000004f;
    border-radius: 10px;
}
.Home-Banner .image-home .content-banner {
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0%;
    align-items: center;
    right: 0px;
    left: 0px;
}
.Home-Banner .image-home {
    /* height: 634px; */
    width: 100%;
    position: relative;
}
.Home-Banner .image-home .content-banner .main-content button.explore {
    color: white;
    background-color: #24642e;
    border: none;
    padding: 11px 36px;
    border-radius: 5px;
    font-size: 16px;
}

.Home-Banner .swiper-pagination-bullet {
    height: 6px !important;
    border-radius: 4px !important;
    width: 15px !important;
    border-radius: 10px !important;
    opacity: 0.51;
    background-color: #fff;
}

.Home-Banner span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 6px !important;
    border-radius: 10px !important;
    width: 26px !important;
    opacity: 0.51 !important;
    background-color: #fff !important;
}
section.Home-Banner {
    position: relative;
    top: -41px;
}
/* .Home-Banner .swiper-pagination-fraction,
.Home-Banner .swiper-pagination-custom,
.Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 80px);
} */
.Home-Banner .swiper-pagination-fraction,
.Home-Banner .swiper-pagination-custom,
.Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
.Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 15%) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}
.Home-Banner .swiper-button-prev.swiper-button-disabled, 
.Home-Banner .swiper-button-next.swiper-button-disabled {
    opacity: 1 !important;
    cursor: auto;
    pointer-events: none;
}
/* Banner Css End */

/************************** Media Query Started ******************************/

@media (min-width: 0px) and (max-width: 390px) {
    .Home-Banner .image-home .content-banner .main-content {
        padding: 7px 32px;
    }
    .Home-Banner .image-home {
        height: 247px;
    }
    .Home-Banner .image-home .content-banner .main-content button.explore {
        padding: 7px 21px;
        font-size: 10px;
    }
    .Home-Banner .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 18px !important;
    }
    .Home-Banner .swiper-pagination-fraction,
    .Home-Banner .swiper-pagination-custom,
    .Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
    .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: none;
    }
}
@media (min-width: 391px) and (max-width: 485px) {
    .Home-Banner .image-home .content-banner .main-content {
        padding: 3px 26px;
        margin: 0px 0px;
    }

    .Home-Banner .image-home .content-banner .main-content button.explore {
        padding: 7px 21px;
        font-size: 10px;
    }
    .Home-Banner .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 18px !important;
    }
    .Home-Banner .image-home {
        height: 100%;
    }
    .Home-Banner .swiper-pagination-fraction,
    .Home-Banner .swiper-pagination-custom,
    .Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
    .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: none;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .Home-Banner .image-home .content-banner .main-content {
        padding: 7px 73px;
    }

    .Home-Banner .image-home .content-banner .main-content button.explore {
        padding: 7px 21px;
        font-size: 13px;
    }
    .Home-Banner .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 18px !important;
    }
    .Home-Banner .image-home {
        height: 100%;
    }
    .Home-Banner .swiper-pagination-fraction,
    .Home-Banner .swiper-pagination-custom,
    .Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
    .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .Home-Banner .image-home .content-banner .main-content {
        padding: 7px 88px;
    }

    .Home-Banner .image-home .content-banner .main-content button.explore {
        padding: 7px 21px;
        font-size: 13px;
    }
    .Home-Banner .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 18px !important;
    }
    .Home-Banner .image-home {
        height: 100%;
    }
    .Home-Banner .swiper-pagination-fraction,
    .Home-Banner .swiper-pagination-custom,
    .Home-Banner .swiper-horizontal > .swiper-pagination-bullets,
    .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .Home-Banner .image-home .content-banner .main-content {
        padding: 19px 114px;
    }
    .Home-Banner .image-home .content-banner .main-content button.explore {
        padding: 7px 21px;
        font-size: 13px;
    }
    .Home-Banner .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 22px !important;
    }
    .Home-Banner .image-home {
        height: 100%;
    }
    .Home-Banner .swiper-pagination-fraction, .Home-Banner .swiper-pagination-custom, .Home-Banner .swiper-horizontal > .swiper-pagination-bullets, .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 7%) !important;
    
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .Home-Banner .swiper-pagination-fraction, .Home-Banner .swiper-pagination-custom, .Home-Banner .swiper-horizontal > .swiper-pagination-bullets, .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 10%) !important;
    }
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .Home-Banner .swiper-pagination-fraction, .Home-Banner .swiper-pagination-custom, .Home-Banner .swiper-horizontal > .swiper-pagination-bullets, .Home-Banner .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, 11%) !important;
       
    }
}

