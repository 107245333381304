.Return_Policy_Banner .Banner_holder {
    background-image: url(../../../../public/assests/Policybanner/return.png);
    aspect-ratio: 1/0.2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    /* align-items: center; */
  }
  .Return_Policy_Banner  .text-holder {
    margin-top: 4%;
}
.Return_Policy_Banner .title {
  color: #222222;
  font-weight: 500;
}