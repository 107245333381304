

/* Address Css started */

.Address {
    padding: 37px 26px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
}

.Address .Main-address {
   
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070;
    border-radius: 5px;
    margin-bottom: 6%;
}

.Address .image-map {
    width: 139px;
    height: 100px;
    position: relative;
}
.Address .right_border {
    border-right: 2px solid #cfcfcf;
    padding: 0px 46px 0px 0px !important;
}

.Address .map {
    width: 100%;
    height: 100%;
}
.Address .circle {
    border-radius: 100px;
    background: #1d44ad;
    width: 34px;
    height: 35px;
    color: white;
    padding: 8px 11px;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
}
.Address .Edit {
    padding: 10px 9px;
    display: flex;
}
.Address .Main-address .Edit  .sub-text {
    color: #323232;
    font-weight: 600;
    margin-bottom: 0px;
    font-size: 15px;
}
.Address .Main-address .Edit .text {
    margin-bottom: 0px;
    color: #323232;
}
.Address .Main-address .Edit  p.text.edit {
    margin-bottom: 0px;
    cursor: pointer;
    color: #1d44ad;
    margin-top: 60px;
}
.Address .form-check-input:checked[type="radio"] {
    cursor: pointer;
    height: 18px;
    width: 18px;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e") !important;
}

.Address .selectOptions__control {
    background-color: #f6f6f6;
    border: none;
}
.Address .form-check-input:checked {
    background-color: white !important;
    border-color: black !important;
    cursor: pointer;
}
.Address .Main-address .Edit  .content {
    margin-left: 16px;
}

.Address label.form-label {
    font-size: 15px;
    color: #323232;
    font-family: Nunito Sans;
}
.Address .form-control {
    padding: 10px 18px;
    border: none;
    background: #f6f6f6 0% 0% no-repeat padding-box !important;
    border-radius: 5px !important;
    color: #A2A2A2!important;
    font-size: 13px !important;
}
.Address .selectOptions__value-container {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    font-size: 13px;
}
.Address-Content {
    padding: 0px 0px 0px 21px;
}
.Address-Content .Address-Content .main-heading-text label.form-check-label {
    margin: 0px 10px;
    font-size: 20px;
    font-weight: bold;
}
label.form-check-label {
    font-weight: 600;
    font-size: 18px;
}
/* Address Css End */

@media (min-width: 0px) and (max-width: 485px) {
    .Address .right_border {
        border-right: none;
        padding: 0px 0px 0px 0px !important;
    }
    .Address {
        padding: 20px 22px;
    }
    .Address .circle {
        width: 24px;
        height: 24px;
        padding: 2px 6px;
    }
    .Address .Main-address {
        margin: 13px 10px;
    }
    .Address-Content {
        padding: 0px 0px 0px 0px;
    }
}
@media (min-width: 486px) and (max-width: 767px) {
    .Address {
        margin: 0px 19px;
    }
    .Address .right_border {
        border-right: none !important;
       padding: 0px 0px 0px 0px !important;
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .Address .Edit {
        justify-content: space-between;
       
    }
    .Address-Content {
        padding: 0px 0px 0px 0px;
    }
}

/* Payment Css Started */
.name {
    display: flex;
}
.adresmain h3 {
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
    padding-left: 25px;
    padding-bottom: 15px;
}

.adresmain .adresss {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px 25px;
    opacity: 1;
    margin-bottom: 26px;
}
.adresmain p.text {
    color: #323232;
}
.main {
    margin-right: 10px;
}
/* .adresmain .adresss .primary {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
  }
  
  .adresmain .adresss .disha {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #323232;
    opacity: 1;
  } */

.Choose_Payment_Option {
    background: #fffaf8 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 13px 25px 0px 25px;
    margin-bottom: 40px;
}

.Choose_Payment_Option .payment_method_card .paycredit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.Choose_Payment_Option .heading-holder h4 {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: #323232;
    margin-bottom: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder {
    display: flex;
    /* margin-bottom: 1%; */
}

.Choose_Payment_Option .radio-btn-input-holder .form-check-label {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    margin-top: 5px;
}

.Choose_Payment_Option .radio-btn-input-holder input.form-check-input {
    font-size: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder .form-check {
    margin-right: 5%;
}

/* .Choose_Payment_Option .payment_method_card {
    padding: 30px 0px;
  } */

.Choose_Payment_Option .bodr_bottom {
    margin-top: 32px;
    /* border-bottom: 1px solid #a7a1a1; */
}

.Choose_Payment_Option .payment_method_card .flex-radio-input {
    display: flex;
    justify-content: normal;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 30px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-label {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    width: 25%;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-control {
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    color: #323232;
    margin-left: 10px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .payment-logo {
    width: 60px;
    height: 30px;
    object-fit: contain;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .payment-logo-holder {
    margin-left: 10px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 0.5px solid #b2b2b2;
    border-radius: 5px;
    padding: 4px 22px;
}

.payment_method_card .flex-radio-input input.form-check-input {
    font-size: 20px;
}

@media (min-width: 280px) and (max-width: 320px) {
    .Choose_Payment_Option .payment_method_card {
        margin-bottom: 5%;
    }

    .Choose_Payment_Option .heading-holder h4 {
        font-size: 15px;
    }

    .Choose_Payment_Option .payment_method_card {
        padding: 14px 15px;
    }

    .adresmain h3 {
        font-size: 18px;
        padding-left: 0px;
        padding-bottom: 5px;
    }
    .adresmain .adresss .primary {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .adresmain .adresss .disha {
        margin-bottom: 5px;
        font-size: 12px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .Choose_Payment_Option .payment_method_card {
        margin-bottom: 5%;
    }

    .Choose_Payment_Option .heading-holder h4 {
        font-size: 15px;
    }

    .Choose_Payment_Option .payment_method_card {
        padding: 14px 15px;
    }
    .adresmain h3 {
        font-size: 18px;
        padding-left: 0px;
        padding-bottom: 5px;
    }
    .adresmain .adresss .primary {
        margin-bottom: 5px;
        font-size: 15px;
    }

    .adresmain .adresss .disha {
        margin-bottom: 5px;
        font-size: 12px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .Choose_Payment_Option .payment_method_card {
        margin-bottom: 5%;
    }

    .Choose_Payment_Option .heading-holder h4 {
        font-size: 15px;
    }
    .Choose_Payment_Option .payment_method_card {
        padding: 14px 15px;
    }
    .adresmain h3 {
        font-size: 19px;
        padding-left: 0px;
        padding-bottom: 5px;
    }
    .adresmain .adresss .primary {
        margin-bottom: 5px;
        font-size: 16px;
    }

    .adresmain .adresss .disha {
        margin-bottom: 5px;
        font-size: 13px;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .Choose_Payment_Option .payment_method_card {
        margin-bottom: 5%;
    }

    .Choose_Payment_Option .heading-holder h4 {
        font-size: 15px;
    }
    .adresmain h3 {
        font-size: 19px;
        padding-left: 0px;
        padding-bottom: 5px;
    }
    .adresmain .adresss .primary {
        margin-bottom: 5px;
        font-size: 16px;
    }

    .adresmain .adresss .disha {
        margin-bottom: 5px;
        font-size: 13px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Choose_Payment_Option .heading-holder h4 {
        font-size: 15px;
    }
    .adresmain h3 {
        font-size: 20px;
        padding-left: 0px;
        padding-bottom: 5px;
    }
    .adresmain .adresss .primary {
        margin-bottom: 5px;
        font-size: 17px;
    }

    .adresmain .adresss .disha {
        margin-bottom: 5px;
        font-size: 14px;
    }
}
/* Payment Css End */

/* Order Review css started */
.OrderReview .Address .form-check-input:checked[type="checkbox"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%2324642E%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
}
.OrderReview .Address .form-check-input[type="checkbox"] {
    border: 1px solid #24642e;
    font-size: 20px;
    border-radius: 4px;
}
.OrderReview .Address .OrderDetails {
    background: #fffaf8 0% 0% no-repeat padding-box;
    border: 1px solid #ffd5d5;
    border-radius: 5px;
    padding: 16px 18px;
    width: 100%;
    margin-left: 15px;
    margin-bottom: 4%;
}
.OrderReview .Address img.payment-logo {
    width: 100%;
    height: 100%;
    border-radius: 11px;
}
.OrderReview .Address .image-product {
    width: 100%;
    height: 127px;
    border-radius: 11px;
}
.OrderReview .Address .OrderDetails .Details {
    /* display: flex;
    justify-content: space-between; */
}
.OrderReview .Address .OrderDetails .Details .mini-text {
    display: flex;
    /* justify-content: space-between; */
}
.OrderReview .Address .OrderDetails .Details .delete-main {
    padding: 4px 10px;
    background-color: #f5f5f5;
    height: 30px;
    width: 30px;
    border-radius: 100px;
}
.OrderReview .Address .OrderDetails .Details .delete-main .icon {
    color: #878181;
    font-size: 12px;
}
.OrderReview .Address .Deatils-address {
    display: flex;
    align-items: baseline;
}
.OrderReview .Address .check-main .form-check-input[type="checkbox"] {
    font-size: 19px;
}
.OrderReview .Address .total-amount {
    padding: 17px 17px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 7px #00000029;
}
.OrderReview .Address .price-text {
    font-weight: bold;
    color: #24642e;
}
.OrderReview .Address .place-order button.order {
    width: 100%;
    padding: 7px 0px;
    border: none;
    font-size: 14px;
    color: #ffffff;
    /* background: #56a462 0% 0% no-repeat padding-box;
    border-radius: 5px; */
    font-family: Nunito Sans;
}

.OrderReview .Address .Delete-order {
    text-align: -webkit-right;
}
/* .OrderReview  .Order-payment {
    margin-top: 9%;
} */

/* media Query Started */

@media (min-width: 0px) and (max-width: 767px) {
    .OrderReview .Address .check-main .form-check-input[type="checkbox"] {
        font-size: 16px;
    }
    .OrderReview .Address .Deatils-address {
        display: block;
    }
    .OrderReview .Address .OrderDetails {
        margin-left: 0px;
    }
    .OrderReview .Address .image-product {
        width: 100%;
        height: 100%;
        margin-bottom: 7%;
    }
    .OrderReview .Address .form-check-input[type="checkbox"] {
     
        margin-bottom: 16px;
    }
}
