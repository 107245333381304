/* Account css started */

.Account .account-main {
    margin-top: 3%;
    padding: 34px 0px;
}
.Account {
    margin-bottom: 7%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
}
.Account .account-main .image-account img.count-logo {
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border-radius: 11px;
}
.Account .account-main .image-account img.count-logo:hover {
    transform: scale(1.1);
}
.Account .account-main .image-account {
    overflow: hidden;
    border-radius: 11px;
    position: relative;
}
.Account .account-main .content {
    width: 100%;
    right: 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.Account .account-main .content p.product-detail {
    text-align: center;
    color: white;
    font-weight: 400;
}
.Account .account-main .accounts-class {
    position: relative;
    margin-bottom: 3%;
}

.Account .account-main .overlay {
    position: absolute;
    border: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(0deg, #003308 0%, #00000066 48%, #24642e00 100%) 0% 0% no-repeat padding-box;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.3s;
    border-radius: 11px;
}

.Account .account-main p.product-detail {
    margin-top: 19%;
    color: white;
    font-weight: 300;
}

/* Account css End */
