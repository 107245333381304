/* Yoga Css Started */

.Yoga .yoga-image-main img.yoga-women {
    width: 100%;
    height: 100%;
}
.Yoga .Main .yoga-content img.yoga {
    width: 100%;
    height: 100%;
    display: block;
}
.Yoga .Main .yoga-content img.yoga2 {
    width: 100%;
    height: 100%;
    display: none;
}
.Yoga .Main {
    position: relative;
}
section.Yoga {
    margin-top: 7%;
    margin-bottom: 5%;
}
.yoga-content {
    height: 443px;
    /* aspect-ratio: 4/1; */
}
.Yoga .Main .yoga-content .content {
    position: absolute;

    top: 44px;
}
.Yoga .content h1.mid-title {
    /* font-size: 30px; */
    color: #24642e;
    font-weight: 600;
    /* line-height: 41px; */
    text-align: center;
}
.Yoga .Main .content .Main-Content .Meditations .circle {
    border-radius: 100px;
    width: 56px;
    height: 56px;
    background: #ffffff6e 0% 0% no-repeat padding-box;
}
.Yoga .Main .content .Main-Content .Meditations .circle {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    left: 0px;
    text-align: justify;
    justify-content: center;
}
.Yoga .Main .content .Main-Content .Meditations .circle img.imag-1 {
    width: 24px;
    height: 28px;
}
.Yoga .Main .content .Main-Content .Meditations .meditation-content h1.awake {
    font-size: 16px;
    font-weight: bold;
    font-family: myFont;
}
.Yoga .Main .content .Main-Content .Meditations {
    display: flex;
    margin-top: 2%;
}
.Yoga .Main .content .Main-Content .Meditations .meditation-content {
    margin: 11px 28px;
}
.Yoga .Main .content .Main-Content {
    margin-top: 6%;
}
@media (min-width: 0px) and (max-width: 485px) {
    .Yoga .Main .content .Main-Content .Meditations .meditation-content h1.awake {
        font-size: 13px;
    }
    .Yoga .Main .content .Main-Content .Meditations .meditation-content {
        margin: 11px 16px;
    }
    .Yoga .Main .content .Main-Content .Meditations .circle {
        width: 50px;
        height: 50px;
    }
    .Yoga .Main .content .Main-Content .Meditations .circle img.imag-1 {
        width: 22px;
        height: 24px;
    }

}

@media (min-width: 0px) and (max-width: 575px) {
    .Yoga .Main .yoga-content img.yoga2 {
        display: block;
    }
    .Yoga .Main .yoga-content img.yoga {
        display: none;
    }
    .yoga-content {
        height: 432px;
    }
}
