.main .Successfull_Lottie_icon {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin: 0px auto 10px auto;
}

@media (min-width: 0px) and (max-width: 992px) {
    .main .Successfull_Lottie_icon {
        width: 50px;
        height: 50px;
    }

    }

    @media (min-width: 992px) and (max-width: 1023px) {
        .main .Successfull_Lottie_icon {
            width: 50px;
            height: 50px;
        }
        .cont_sectionn .sent-successfull .success-lottie {
           
            top: 8px;
        }
      }
      
      @media (min-width: 1024px) and (max-width: 1199px) {
        .main .Successfull_Lottie_icon {
            width: 50px;
            height: 50px;
        }
        .cont_sectionn .sent-successfull .success-lottie {
           
            top: 8px;
        }
      }

      @media (min-width: 1200px) and (max-width: 1399px) {
        .main .Successfull_Lottie_icon {
            width: 80px;
            height: 80px;
        }
      }