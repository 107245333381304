order_css started

.Your-Order  .oreder_main {
    background-color: white;
    padding: 26px 53px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
.order_main{
    background-color: white;
    padding: 26px 53px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
.Your-Order .ordermain_divv .orderlist_imgdiv {
    height: 208px;
    width: 216px;
}
.Your-Order .mid-title {
    color: #323232;
    font-weight: bold;
    text-align: center;
}
.Your-Order .Orders-Main {
    margin-top: 3%;
    padding: 35px 60px;
    background: transparent linear-gradient(0deg, #c8c8c803 0%, #c6c6c629 100%) 0% 0% no-repeat padding-box;
}
.Your-Order .order_data {
    padding: 0px 39px;
}

.Your-Order .ordermain_divv .orderlist_img {
    width: 100%;
    height: 100%;
}
.Your-Order .ordermain_divv .orderlist_div .price-text {
    color: #24642e;
    font-weight: bold;
}

.Your-Order .ordermain_divv .orderlist_div .mini-text {
    color: #323232;
}
.Your-Order .ordermain_divv .ratemain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
}
.Your-Order span.MuiRating-root.MuiRating-sizeMedium.css-1qqgbpl-MuiRating-root {
    font-size: 26px;
}
.Your-Order .ordermain_divv .ratemain .ratingmainss {
    display: flex;
    align-items: center;
}

.Your-Order .ordermain_divv .ratemain .ratingmainss svg.star-svg {
    width: 23px;
}

.Your-Order .ordermain_divv .ratemain .deliverd {
    color: #25a142;
}

/* order_css ended */
.stepper-div .ranges-holder {
    display: flex;
    justify-content: space-between;
  }
  
  
  
   
  
 
  
  .Your-Order .RSPBprogressBar {
    width: 90%;
    height: 5px;
  }
  
  .Your-Order .RSPBprogressBar .indexedStep.accomplished {
    background-color: #24642E;
    width: 18px;
    height: 18px;
}
  
  .Your-Order .RSPBprogressBar .indexedStep.null {
    width: 18px;
    height: 18px;
  }
  .Your-Order .range-text-div  p.date {
    font-family: Nunito Sans;
    color: black;
    text-align: justify;
    font-size: 12px;
}
.Your-Order  .order-Steps {
   margin-top: 4%;
}
  .Your-Order .RSPBprogressBar .indexedStep1.accomplished {
    background-color: #086A0F;
    width: 18px;
    height: 18px;
    border-radius: 50px;
  }
  .Your-Order .Button-Main {
    text-align: end;
  }
  .Your-Order .Button-Main button.cancel {
    background: #56A462 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 6px 65px;
    border: none;
    color: white;
}
.Your-Order .Button-Main button.return {
    background: #AB755C 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 6px 65px;
    border: none;
    color: white;
}
  .Your-Order .range-text-div .mini-text {
    text-align: start;
    font-weight: bold;
}
  .Your-Order .RSPBprogressBar .indexedStep1.null {
    width: 15px;
    height: 15px;
  }
  .Your-Order  .stepper-div {
    height: 69px;
}

.Your-Order  .order-border {
    margin-top: 4%;
    margin-bottom: 2%;
    border: 0.4px solid #5b402069;
    height: 2px;
}
  .Your-Order .indexedStep1 {
    position: relative;
    color: white;
    width: 15px;
    height: 15px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Your-Order .modal-body {
    border: none;
    height: 100%;

    overflow-y: scroll;
    margin-bottom: 5%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 17px #00000012;
    border: 1px solid #f6f6f6;
    border-radius: 10px;
  }
  
  .Your-Order .modal-content {
    background: none;
    border: none;
  }
  
  .Your-Order .border-text-right {
    border-right: 1px solid #707070;
  }
  
  .Your-Order .return-button {
    margin-top: 15%;
  }
  
  .Your-Order .return-button .return-btn {
    background: #B7B7B7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFF;
    height: 38px;
    padding: 0px 55px;
    font-size: 18px;
    border: none;
  }
  .Your-Order .range-text-div {
    margin: 0px;
  }
  .Your-Order .oreder_main {
    background-color: white;
    padding: 26px 53px;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
}
  .Your-Order .return-button .return-btn:hover {
    background: #B7B7B7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFF;
    height: 38px;
    padding: 0px 55px;
    font-size: 18px;
    border: none;
  }
  .Your-Order .stepper-div .indexedStep {
    position: relative;
    color: white;
    width: 15px;
    height: 15px;
    font-size: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #5B4020;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Your-Order .range-text-div {
 
    position: absolute;
    top: 27px;
    left: 0;
 
    width: 100px;
    text-align: center;
}
.Your-Order .stepper-div .indexedStep.accomplished {
    background-color: #24642E;
    width: 15px;
    height: 15px;
}
.Your-Order .RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}
.Your-Order .RSPBprogressBar {
    height: 5px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: #5B4020;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
}
.Your-Order .RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
  
    transition-property: all;
    transition-timing-function: ease;
}
.Your-Order .ratemain .mini-text {
    font-weight: bold;
    
 
}

.Your-Order .ratemain p.mini-text.delivered {
    font-weight: bold;
    color: #25A142;
}
@media (min-width:0px) and (max-width:767px) {
    .Your-Order .ordermain_divv .ratemain {
        display: block;
    }
    .Your-Order .ordermain_divv .ratemain {
        margin-top: 3%;
      
    }
    .Your-Order .Orders-Main {
        padding: 35px 20px;
       
    }
    .Your-Order .order_data {
        padding: 0px  0px;
    }
    .Your-Order .ordermain_divv .oreder_main {
       padding: 26px 19px;
    }
    .Your-Order .image {
        text-align: -webkit-center;
    }
    .Your-Order .ordermain_divv .orderlist_imgdiv {
        height: 100%;
        width: 100%;
    }
    .Your-Order .Button-Main button.cancel {
        padding: 6px 24px;
    }
    .Your-Order .Button-Main button.return {
        padding: 6px 31px;
    }
    .Your-Order  .stepper-div {
        margin-top: 49%;
        margin-bottom: 48%;
     
    }
    .Your-Order .RSPBprogressBar {
        transform: rotate(90deg);
        width: 90%;
      
    }
    .Your-Order .range-text-div {
        top: -102px;
        left: -27px;
        transform: rotateZ(271deg);
    }
    .Your-Order .Button-Main {
        text-align: center;
    }
}
@media (min-width:768px) and (max-width:991px) {
    .Your-Order .ordermain_divv .ratemain {
        display: block;
    }
    .Your-Order .ordermain_divv .ratemain {
        margin-top: 3%;
      
    }
    .Your-Order .Orders-Main {
        padding: 35px 20px;
       
    }
    .Your-Order .order_data {
        padding: 0px  0px;
    }
    .Your-Order .ordermain_divv .oreder_main {
       padding: 26px 19px;
    }
   
}
@media (min-width:992px) and (max-width:1199px) {
    .Your-Order .Orders-Main {
        padding: 35px 45px;
       
    }
    .Your-Order .ordermain_divv .oreder_main {
      padding: 26px 35px;
    }
    .Your-Order .order_data {
        padding: 0px 0px;
    }
}