/* OurMission CSS Started */

.OurMission .Misiion-Image {
    aspect-ratio: 3/2;
}

.OurMission .Misiion-Image img.mission {
    width: 100%;
    height: 100%;
}

.OurMission .OurMission-Main {
    text-align: right; /* Changed from -webkit-right to right */
}

section.OurMission {
    margin-top: 6%;
    margin-bottom: 4%;
}

.OurMission .vission {
    margin-top: 10%;
}

.OurMission .Content-mission {
    margin-top: 6%;
    margin-left: 3%;
}

.OurMission .big-title {
    position: relative;
    border-bottom: 0;
}

.OurMission .main-vission .Content-mission .big-title::after {
    content: "";
    position: absolute;
    bottom: 23px;
    left: 32%;
    transform: translateX(-50%);
    height: 6px;
    width: 60px;
    background-color: #ab755c;
    visibility: hidden;
}
.OurMission .main-mission .Content-mission .big-title::after {
    content: "";
    position: absolute;
    bottom: 23px;
    left: 32%;
    transform: translateX(-50%);
    height: 6px;
    width: 60px;
    background-color: #ab755c;
    visibility: hidden;
}
.OurMission .main-vission:hover .big-title::after
{
    visibility: visible;
    left: 38%;
    -webkit-animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.OurMission .main-mission:hover .big-title::after {
    visibility: visible;
    left: 33%;
    -webkit-animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.OurMission .main-vission:hover span.our,
.OurMission .main-mission:hover span.our {
    color: #24642e;
}



/* @keyframes slide-right {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
} */




@-webkit-keyframes slide-br {
    0% {
      -webkit-transform: translateY(0) translateX(0);
              transform: translateY(0) translateX(0);
    }
    100% {
      -webkit-transform: translateY(30px) translateX(30px);
              transform: translateY(30px) translateX(30px);
    }
  }
  @keyframes slide-br {
    0% {
      -webkit-transform: translateY(0) translateX(0);
              transform: translateY(0) translateX(0);
    }
    100% {
      -webkit-transform: translateY(30px) translateX(30px);
              transform: translateY(30px) translateX(30px);
    }
  }
/* OurMission CSS End */

@media (min-width: 0px) and (max-width: 575px) {
    .OurMission .mini-text-p {
     text-align: justify;
    }
}
