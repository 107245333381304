.wishlist_section .wishlistmain{
    background: transparent linear-gradient(0deg, #C8C8C803 0%, #C6C6C629 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 25px;
    margin-top: 3%;
}
section.wishlist_section {
    margin-top: 0%;
    margin-bottom: 4%;
}
