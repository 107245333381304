.ReturnOrder .mid-title {
    text-align: center;
    font-weight: 600;
}
.ReturnOrder .return {
    background: transparent linear-gradient(0deg, #c8c8c803 0%, #c6c6c629 100%) 0% 0% no-repeat padding-box;
}
.ReturnOrder .Reason {
    padding: 11px 58px;
    background: #fff6f2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 2%;
    margin-bottom: 3%;
}
.ReturnOrder .select {
    display: flex;
    align-items: center;
    margin: 0px 0px;
}
.ReturnOrder .cancellation .text{
    color: black;
}
svg.svg-inline--fa.fa-angle-right {
    padding: -6px 7px;
    margin: 0px 22px;
}
.ReturnOrder .Reason .select .mini-text {
  margin-bottom: 0px;
}
.ReturnOrder .cancellation {
    padding: 18px 52px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 9px #00000021;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    height: 201px;
}
.ReturnOrder .Button-Main {
    margin-top: 2%;
    text-align: center;
}
section.ReturnOrder.Your-Order {
    margin-bottom: 4%;
    margin-top: 3%;
}

@media (min-width:0px) and (max-width:485px){
    .ReturnOrder .Reason {
        padding: 11px 27px;
      
    }
    .ReturnOrder .Button-Main {
        display: flex;
        margin-top: 2%;
        text-align: center;
        justify-content: center;
    }
  
}