

.favorite-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.red-heart {
  color: red;
}

.black-heart {
  color:black;
}

@media (min-width:0px) and (max-width:991px) {
  .ProductCards  .Product-Card .heart-icon {
    font-size: 19px;
   
}
}