/* Footer css started */

.Footer {
    padding-bottom: 1%;
    padding-top: 3%;
    /* padding: 63px 0px; */
    background-color: #184d3d;
}
.Footer .text {
    padding: 9px 0px;
    margin-bottom: 1px;
}
.Footer .border-footer {
    padding: 12px 0px;
    border-bottom: 1px solid white;
}
.Footer p.text.copy {
    padding-top: 26px;
    margin-bottom: 0px;
}

.Footer .footer-link-text {
    display: flex;
    justify-content: end;
}
.Footer .main {
    margin: 0px 25px;
}
.Footer .main .sub-text {
margin-bottom: 24px;
}
@media (min-width: 0px) and (max-width: 1199px) {
    .Footer .footer-link-text {
        display: flex;
        justify-content: start;
    }
    .Footer .sub-text {
        border-bottom: 1px solid white;
        width: fit-content;
    }
}
