/* WhoWeAre Css Started */

.WhoWeAre {
    margin-bottom: 3%;
    margin-top: 1%;
    background-color: #f5f5f5;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border-radius: 9px; */
    position: relative;
    padding-top: 1%;
    padding-bottom: 4%;
}
.WhoWeAre .WhoWeAre-image .image-2 {
    position: absolute;
    top: 127px;
    z-index: 1;
    left: 21px;
    width: 386px;
    height: 399px;
}
.WhoWeAre .WhoWeAre-image .Imag1 {
    position: absolute;
    z-index: 0;
    right: 0px;
    width: 386px;
    height: 399px;
}
.WhoWeAre .WhoWeAre-image img.who1 {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.WhoWeAre .Content-Who-are p.sub-text {
    color: #222222;
    font-weight: 500;
}
.WhoWeAre .Content-Who-are button.text {
    color: #080000;
    padding: 14px 20px;
}
.WhoWeAre .Content-Who-are {
    margin-top: 16%;
}
.WhoWeAre .Know {
    margin: 18px 66px;
}

.WhoWeAre .Know .know-more {
    padding: 10px 20px;
    /* font-size: 15px; */
    font-weight: bold;
    border: 1px solid black;
    border-radius: 5px;
}
.WhoWeAre .Know .know-more:hover {
    background-color: #080000;
    color: white;
}
.WhoWeAre .heart-icon {
    margin: 0px 14px;
}
.icon {
    font-size: 16px;
    color: currentColor;
}

/* .WhoWeAre .Content-Who-are {
    margin-left: -31px;
} */
.WhoWeAre .Content-Who-are .border-who {
    margin-top: 1%;
    border-left: 1px solid #999999;
    height: 86px;
    margin-left: 19px;
    margin-right: 44px;
}
.WhoWeAre .Content-Who-are .text-content {
    display: flex;
    margin-top: 8%;
}
.WhoWeAre .Know-More {
    margin: 4px 60px;
}

.WhoWeAre .Know-More .text {
    border-radius: 7px;
    font-weight: bold;
    font-size: 15px;
    font-family: "Nunito Sans";
}
.WhoWeAre-image {
    position: relative;
}
.Image-One {
    text-align: -webkit-center;
}
/* WhoWeAre Css End */

/* Media Query Started */

@media (min-width: 0px) and (max-width: 390px) {
    .WhoWeAre-image {
        position: unset;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        position: unset;
        text-align: center;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        position: unset;
        text-align: center;
          aspect-ratio: 3/3;
        width: 100%;
        height: 100%;
    }
    .Image-Two {
        text-align: -webkit-center;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 100%;
        height: 100%;
        aspect-ratio: 3/3;
    }
    .WhoWeAre {
        height: auto;
    }
    .WhoWeAre .Know-More {
        margin-left: 21%;
    }
    .WhoWeAre .Content-Who-are .border-who {
       
        display: none;
    }
    .WhoWeAre .Know-More .text {
        font-size: 12px;
    }
    .WhoWeAre .Content-Who-are p.sub-text {
      
        text-align: justify;
    }
    .WhoWeAre .big-title {
      
        text-align: center;
    }
    .WhoWeAre .Know {
        margin: 0px 0px;
        text-align: center;
    }
    .WhoWeAre .Content-Who-are button.text {
        padding: 8px 9px;
    }
    .WhoWeAre {
        height: auto;
        margin-top: 10%;
    }
    .WhoWeAre .Know .know-more {
        padding: 7px 14px;
        
    }
}

@media (min-width: 391px) and (max-width: 485px) {
    .WhoWeAre-image {
        position: unset;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        position: unset;
        text-align: center;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        position: unset;
        text-align: center;

        width: 300px;
        height: 300px;
    }
    .Image-Two {
        text-align: -webkit-center;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 300px;
        height: 300px;
    }
    .WhoWeAre {
        height: auto;
    }
    .WhoWeAre .Content-Who-are {
        margin-top: 4%;
    }
    .WhoWeAre .Content-Who-are .border-who {
       display: none;
    }
    .WhoWeAre .Know-More .text {
        font-size: 12px;
    }
    .WhoWeAre .Content-Who-are button.text {
        padding: 8px 9px;
    }
    .WhoWeAre .Content-Who-are .text-content {
       
        margin-top: 5%;
    }
    .WhoWeAre {
        height: auto;
        margin-top: 10%;
    }
    .WhoWeAre .Content-Who-are p.sub-text {
      
        text-align: justify;
    }
    .WhoWeAre .big-title {
      
        text-align: center;
    }
    .WhoWeAre .Know {
        margin: 0px 0px;
        text-align: center;
    }
 
    .WhoWeAre {
        height: auto;
        margin-top: 6%;
    }
}
@media (min-width: 486px) and (max-width: 575px) {
    .WhoWeAre-image {
        position: unset;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        position: unset;
        text-align: center;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        position: unset;
        text-align: center;

        width: 300px;
        height: 300px;
    }
    .Image-Two {
        text-align: -webkit-center;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 300px;
        height: 300px;
    }
    .WhoWeAre {
        height: auto;
    }
    .WhoWeAre .Content-Who-are {
        margin-top: 4%;
    }
    .WhoWeAre .Content-Who-are .border-who {
       display: none;
    }
    .WhoWeAre .Know-More .text {
        font-size: 12px;
    }
    .WhoWeAre .Content-Who-are button.text {
        padding: 8px 9px;
    }
    .WhoWeAre .Content-Who-are .text-content {
       
        margin-top: 5%;
    }
    .WhoWeAre {
        height: auto;
        margin-top: 10%;
    }
    .WhoWeAre .Content-Who-are p.sub-text {
      
        text-align: justify;
    }
    .WhoWeAre .big-title {
      
        text-align: center;
    }
    .WhoWeAre .Know {
        margin: 0px 0px;
        text-align: center;
    }
    .yoga-content {
        height: 371px;
        /* aspect-ratio: 4/1; */
    }
    .WhoWeAre {
        height: auto;
        margin-top: 6%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .WhoWeAre-image {
        position: unset;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        position: unset;
        text-align: center;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        position: unset;
        text-align: center;

        width: 300px;
        height: 300px;
    }
    .Image-Two {
        text-align: -webkit-center;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 300px;
        height: 300px;
    }
    .WhoWeAre {
        height: auto;
    }
    .WhoWeAre .Content-Who-are {
        margin-top: 4%;
    }
    .WhoWeAre .Content-Who-are .border-who {
       display: none;
    }
    .WhoWeAre .Know-More .text {
        font-size: 12px;
    }
    .WhoWeAre .Content-Who-are button.text {
        padding: 8px 9px;
    }
    .WhoWeAre .Content-Who-are .text-content {
       
        margin-top: 5%;
    }
    .WhoWeAre {
        height: auto;
        margin-top: 10%;
    }
    .WhoWeAre .Content-Who-are p.sub-text {
      
        text-align: justify;
    }
    .WhoWeAre .big-title {
      
        text-align: center;
    }
    .WhoWeAre .Know {
        margin: 0px 0px;
        text-align: center;
    }
    .yoga-content {
        height: 371px;
        /* aspect-ratio: 4/1; */
    }
    .WhoWeAre {
        height: auto;
        margin-top: 6%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .WhoWeAre-image {
        position: unset;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        position: unset;
        text-align: center;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        position: unset;
        text-align: center;

        width: 300px;
        height: 300px;
    }
    .Image-Two {
        text-align: -webkit-center;
    }
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 300px;
        height: 300px;
    }
    .WhoWeAre {
        height: auto;
    }
    .WhoWeAre .Content-Who-are {
        margin-top: 4%;
    }
    .WhoWeAre .Content-Who-are .border-who {
       display: none;
    }
    .WhoWeAre .Know-More .text {
        font-size: 12px;
    }
    .WhoWeAre .Content-Who-are button.text {
        padding: 8px 9px;
    }
    .WhoWeAre .Content-Who-are .text-content {
       
        margin-top: 5%;
    }
    .WhoWeAre {
        height: auto;
        margin-top: 10%;
    }
    .WhoWeAre .Content-Who-are p.sub-text {
      
        text-align: justify;
    }
    .WhoWeAre .big-title {
      
        text-align: center;
    }
    .WhoWeAre .Know {
        margin: 0px 0px;
        text-align: center;
    }

    .WhoWeAre {
        height: auto;
        margin-top: 6%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .WhoWeAre .WhoWeAre-image .image-2 {
      
        left: -20px;
        top: 84px;
       
    }  
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .WhoWeAre .WhoWeAre-image .image-2 {
        width: 300px;
        height: 300px;
        left: 67px;
        top: 84px;
       
    }  
}
@media (min-width: 992px) and (max-width: 1399px) {
    .WhoWeAre .WhoWeAre-image .Imag1 {
        width: 300px;
        height: 300px;
    }
    .WhoWeAre .WhoWeAre-image .image-2 {
        width: 300px;
        height: 300px;
    
       
    }   
    .WhoWeAre .Content-Who-are .text-content {
        display: flex;
        margin-top: 5%;
    }
    .WhoWeAre .Content-Who-are .border-who {
        margin-left: 40px;
        margin-right: 32px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .WhoWeAre {
        height: auto;
        padding-bottom: 2%;
    }
    
}
