.Modal-holder .modal-header {
    background: #000;
    border-radius: 0px;
}

.Modal-holder .modal-header .modal-title {
    font-size: 20px;
    /* font-weight: 600; */
    color: #ffffff;
    margin: 0px auto;
}

.Modal-holder .modal-header .btn-close {
    margin: 0px;
    color: #fff;
    filter: invert(1);
    opacity: 1;
}

.Modal-holder .form-control {
    border: none;
    border-bottom: 1.8px solid #7070707d;
    border-radius: 0px;
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.Modal-holder .LogIn .bg-color .log_in_form .form-control::placeholder {
    font-size: 15px;
    color: #a2a2a2;
}

.LogIn .bg-color .log_in_form .form-control:focus {
    box-shadow: none;
}

.Modal-holder .modal-content {
    border-radius: 0px;
}

.Modal-holder .LogIn .bg-color .log_in_form .form-text {
    display: flex;
    justify-content: end;
    align-items: center;
}

.Modal-holder .LogIn .bg-color .log_in_form .Forgot_password {
    font-size: 15px;
    font-weight: 600;
    color: #24642e;
    text-align: right;
    cursor: pointer;
}

.Modal-holder .LogIn .bg-color .log_in_form .log_in_with p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    display: flex;
}

.Modal-holder .LogIn .bg-color .back_to_span {
    margin: 20px 0px 0px 0px;
}

.Modal-holder .LogIn .bg-color .back_to_span p {
    font-size: 12px;
    font-weight: 500;
    color: #5b4020;
    text-align: center;
    margin-bottom: 0px;
}

.Modal-holder .LogIn .bg-color .log_in_form .btn-holder {
    text-align: center;
    margin-top: 25px;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account {
    margin: 20px 0px;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    cursor: pointer;
}

.LogIn .bg-color .log_in_form .create_account .login_text span {
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
}

.Modal-holder .LogIn .bg-color .heading-holder p {
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.Modal-holder .LogIn .bg-color .heading-holder span {
    font-size: 15px;
    font-weight: 700;
}

.Modal-holder .LogIn .bg-color .log_in_form .create_account .cancel-text {
    text-align: center;
    text-decoration: underline;
    font-size: 15px;
    display: flex;
    font-weight: 400;
    color: #323232;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}
.Modal-holder button.SubmitBtn.green.text {
    border: none;
    padding: 9px 67px;
}
.Modal-holder button.SubmitBtn-main.text {
    border: 1px solid grey;
    padding: 8px 73px;
    color: black;
    border-radius: 5px;
}
.Modal-holder button.SubmitBtn-main.green.text {
  padding: 8px 73px;
  border: none;
  color: white;
}
/* .btn-holder button.SubmitBtn {
  border: none;
  padding: 8px 73px;
} */
.Modal-holder .Rate {
    display: flex;
    justify-content: space-between;
}
.Modal-holder .mini-text {
    color: #707070;
}
.Modal-holder .camera {
    border-radius: 5px;
    padding: 4px 9px;
    width: fit-content;
    border: 1px solid grey;
    color: #707070;
}
.LogIn .bg-color .log_in_form .log_in_with .log_in_with {
    margin-top: 10%;
}

.LogIn .bg-color .log_in_form .log_in_with p:before,
.LogIn .bg-color .log_in_form .log_in_with p:after {
    color: white;
    content: "";
    flex: 1;
    border-bottom: 1px solid #979797;
    margin: auto 5px;
}

.LogIn .otp-holder {
    display: flex;
}

.LogIn .otp-holder .otp-text-holder {
    margin: 0px 5px;
}

.LogIn .bg-color .log_in_form {
    margin-top: 20px;
}

.LogIn .bg-color .log_in_form .social_img_holder {
    text-align: center;
}

.LogIn .bg-color .log_in_form .social_img_holder .social_img {
    height: 30px;
}

.LogIn .bg-color .log_in_form .password-field-holder {
    position: relative;
}

.LogIn .bg-color .log_in_form .eye-icon-holder {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.LogIn .bg-color .log_in_form .eye-icon {
    font-size: 11px;
    color: #6c757d;
}
.Modal-holder .mini-text.form-check {
    margin: 12px 38px;
}
/* Style the checked radio button with a black circle */
.Modal-holder .form-check-input:checked[type="radio"] {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23000%27/%3e%3c/svg%3e");
}

/* Adjust font size of the label associated with the radio button */
.Modal-holder .form-check-input[type="radio"] + .form-check-label {
    font-size: 18px;
}
.Modal-holder .form-check-input:checked {
    background-color: transparent;
    border-color: black;
    font-size: 15px;
}

/*LogIn form css start*/
@media (min-width: 280px) and (max-width: 320px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }

    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }
    .Modal-holder button.SubmitBtn-main.text {
        padding: 8px 28px;
    }
    .Modal-holder button.SubmitBtn-main.green.text {
        padding: 8px 26px;
    }
    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }
    .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 12px;
    }
    .Modal-holder .modal-header .modal-title {
        font-size: 16px;
    }
    .Modal-holder button.SubmitBtn.green.text {
       
        padding: 7px 68px;
    }
    .Modal-holder button.SubmitBtn.green.text {
        padding: 7px 33px;
    }
    .Login .btn-holder button.YesBtn {
       
        padding: 8px 22px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }
    .Login .btn-holder button.YesBtn {
       
        padding: 8px 22px;
    }
    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }

    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }
    .Modal-holder button.SubmitBtn.green.text {
      
        padding: 7px 45px;
    }
    .Modal-holder button.SubmitBtn-main.green.text {
        padding: 8px 35px;
    }
    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }
    .Modal-holder .form-check-input[type="radio"] + .form-check-label {
        font-size: 12px;
    }
    .Modal-holder .modal-header .modal-title {
        font-size: 16px;
    }
    .Modal-holder button.SubmitBtn-main.text {
        padding: 8px 35px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }

    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }
    .Modal-holder button.SubmitBtn.green.text {
       
        padding: 7px 56px;
    }
    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }

    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .heading-holder {
        margin-top: 5%;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section {
        height: 50vh;
        padding: 0px 0%;
    }
    .Modal-holder button.SubmitBtn.green.text {
      
        padding: 7px 68px;
    }
    .LogIn .img-section .img-holder {
        left: 0;
        right: 0;
    }

    .LogIn .img-section .img-holder .log_in_img {
        height: 200px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .LogIn .bg-color .back_to_span p {
        margin-top: 5%;
    }

    .LogIn .bg-color .heading-holder h4 {
        font-size: 20px;
    }

    .LogIn .bg-color .log_in_form .social_img_holder {
        margin-bottom: 5%;
    }

    .LogIn .img-section .img-holder {
        left: -21%;
    }
    .Modal-holder button.SubmitBtn.green.text {
      
        padding: 7px 68px;
    }
}
