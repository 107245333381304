/* Product_Filter Css Started */

.Product_Filter {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding: 30px 15px;
}

.Product_Filter .heading-holder h3 {
    font-weight: bold;
    color: #222222;
}

.Product_Filter .checkbox-holder .checkbox-holder-section .form-check {
    margin: 10px 0px;
}

.Product_Filter .checkbox-holder .checkbox-holder-section .form-check label.form-check-label {
    font-size: 15px;
    font-weight: 500;
    color: #222222;
}

.Product_Filter .checkbox-holder .price-range-section {
    margin: 30px 0px 0px 0px;
}

.Product_Filter .hr-line-holder {
    margin: 50px 0px 0px 0px;
}
.Product_Filter .checkbox-holder .form-check-input:checked {
    font-size: 18px;
    border-radius: 3px;
    background-color: #5b4020;
    border-color: #5b4020;
}
.Product_Filter .checkbox-holder .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(244, 245, 245, 0.25);
}
.Product_Filter .checkbox-holder .form-check .form-check-input {
    border: 2px solid #5b4020;
    font-size: 18px;
    border-radius: 3px;
}
.Product_Filter .checkbox-holder {
    margin-top: 6%;
}
.Product_Filter .filter-section {
    margin-bottom: 5%;
}
/* Product_Filter.css */

/* range Bar Css */

.Product_Filter {
    padding: 35px 20px;
}
.Product_Filter .range-bar .input-range__track--active {
    background: transparent !important;
}
.Product_Filter .range-bar .input-range__track {
    background: #5b4020 !important;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
}
.Product_Filter .range-bar .input-range__slider {
    appearance: none;
    background: #24642e !important;
    border: 1px solid #3f51b5;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 16px;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 16px;
}
.Product_Filter .range-bar span.input-range__label-container {
    background-color: #5b4020;
    color: white;
    padding: 3px 12px;
}
.Product_Filter .range-bar .input-range__label-container {
    position: relative;
    display: inline-block;
    background-color: #5b4020;
    color: white;
    padding: 3px 12px;
}
.filter-section {
    margin-bottom: 5%;
}
.Product_Filter .range-bar .input-range__label-container::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #232121 transparent transparent transparent;
}
.Product_Filter .range-bar .input-range__label-container {
    left: -50%;
    position: relative;
    top: -17px;
}
.Product_Filter .range-bar .slide {
    display: flex;
    justify-content: space-between;
    margin: 17px 0px;
}
.Product_Filter .range-bar span.input-range__label.input-range__label--min {
    display: none;
}
.Product_Filter .range-bar span.input-range__label.input-range__label--max {
    display: none;
}
.Product_Filter .range-bar span.text {
    color: #222222;
    font-weight: bold;
}
.Product_Filter .range-bar {
    margin-top: 20%;
}
.Product_Filter .price-range-section {
    margin-top: 16%;
}
.Mobile-View{
    display: none;
}
.desktop-view{
    display: block;
}
/* Product_Filter Css End */




/**************** Media Query started ***********************/


@media (min-width:0px) and  (max-width:991px) {
    .Mobile-View{
        display: block;
    }
    .desktop-view{
        display: none;
    }
}
