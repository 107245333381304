.Blog_card {
  margin-bottom: 8%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.Blog_card .img-holder {
  border-radius: 10px;
  aspect-ratio: 1/1;
}

.Blog_card .img-holder .blog-card-image {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.Blog_card:hover .blog-card-image {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.Blog_card .overlay-text-content-holder {
  padding: 30px 0px;
  position: absolute;
  bottom: 30px;
  background: #ffffff82 0% 0% no-repeat padding-box;
  opacity: 1;
  /* height: 65px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Blog_card .overlay-text-content-holder .content-overlay {
  height: 100%;
  width: 100%;
}

.Blog_card .overlay-text-content-holder .text-holder {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
}

.Blog_card .overlay-text-content-holder .text-holder h3 {
  line-height: 23px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 0px;
  padding: 0px 10px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  font-family: myFont;
}
.Blog_card_Section {
  margin-top: 7%;
}
.Blog_card:hover .overlay-color {
  width: 100% !important;
  transition: all 0.3s ease-in-out;
}

.Blog_card:hover .text-holder h3 {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.Blog_card .overlay-text-content-holder .overlay-color {
  padding: 40px 0px;
  position: absolute;
  background: #24642e;
  width: 0%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.Blog_card .overlay-text-content-holder .read-more-text-holder {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  padding-left: 5px;
}

.Blog_card .overlay-text-content-holder .read-more-text-holder .read-more-text {
  opacity: 0;
  margin-left: 0;
  transition: all 0.3s ease-in-out;
}
.Blog_card:hover .read-more-text {
  opacity: 1 !important;
  margin-left: 5px;
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.Blog_card
  .overlay-text-content-holder
  .read-more-text-holder
  .read-more-text
  p {
  text-align: left;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 500;
  color: #24642e;
  margin-bottom: 0px;
  font-family: myFont;
}

.Blog_card
  .overlay-text-content-holder
  .read-more-text-holder
  .read-more-text
  span {
  text-align: left;
  font-size: 11px;
  font-weight: 500;
  color: #202020;
}
