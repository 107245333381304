/* Header css started */

section.Header {
    position: sticky;
    top: 0px;
    z-index: 999;
}
.Header .main-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000000f;
    position: relative;
}
.Header .main-header .sub-header {
    /* height: 30px; */
    background: #24642e 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
}
.Header .main-header .sub-header .min-header {
    padding: 9px 46px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.Header .main-header .sub-header .min-header .sub-text {
    font-size: 13px;
    color: #ffffff;
    font-family: "Nunito Sans";
    margin-bottom: 0px;
    font-weight: 300;
    cursor: pointer;
}
.Header .main-header .main .main-header-content .main-logo .logo-header img.logo {
    height: 100%;
    width: auto;
}
.Header .main-header .main .main-header-content .main-logo .logo-header {
    width: 85px;
    height: 48px;
    margin-left: 220px;
}
.Header .main-header .main .main-header-content .main-logo {
    /* text-align: -webkit-right; */
    /* position: relative; */
    /* right: 78px; */
    margin: 0px auto;
}
.Header .main-header .sub-header .min-header .log {
    display: flex;
}
.Header .main-header .main .main-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header input.form-control.form-control-sm {
    width: auto;
    border-top: 0px;
    border-radius: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid black;
    color: black;
    font-weight: 600;
}
.Header .main-header .main .Main .cart-section {
    margin: -3px 7px;
    position: relative;
}
.Header .search {
    position: absolute;
    top: 4px;
    right: 16px;
    z-index: 1;
}
.Header .main {
    padding: 12px 0px;
}
.Header .cart {
    margin: 0px 9px;
    display: flex;
    align-items: center;
}
.Header .heart {
    display: flex;
    align-items: center;
    margin-left: 13px;
    position: relative;
}
.Header p.number {
    margin: 0px 7px;
}
.Header .Desktop {
    display: block;
    padding: 0px 16px;
}
.Header .Mobile {
    display: none;
}
.Mobile-header {
    display: none;
}
.Header p.account {
    color: #222222;
    font-size: 12px;
    font-family: Nunito Sans;
    margin-bottom: 10px;
}
@keyframes slide-left {
    0% {
        transform: translateX(10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.Header .search-input {
    width: 0;
    opacity: 0;
    transition: width 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}
.Header a.nav-link {
    padding: 9px 28px;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    font-weight: 700;
    color: #000000;
}
.Header .treattabitem.nav-item a.nav-link.active::after {
    content: "";
    border-bottom: 5px solid transparent;
    position: absolute;
    border-radius: 15px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
}
.Header a.nav-link.active::after {
    content: "";
    border-bottom: 5px solid #24642e;
    position: absolute;
    border-radius: 15px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 35px;
}

.Header .search-input.open {
    width: 150px;
    margin-right: 10px;
    opacity: 1;
    display: block;
    animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.Header .Main {
    display: flex;
    justify-content: right;
}

.Header.scrolled {
    background-color: white;
}

/***************** DropDown Css Started *********************/

.Header .dropdown-menu.show {
    position: absolute;
    width: 100% !important;
    left: calc(50% - 540px) !important;
    max-width: 1080px !important;
    padding: 0 !important;
    transform: translate3d(0px, 0px, 0px) !important;
    top: 150px !important;
}

section.Header .dropdown {
    position: unset;
}

.Header .deskdroppp .droprows1 {
    padding: 50px 0px;
    height: 100%;
    background-color: #ab755c;
}

.Header .deskdroppp .tabbmains .treattabitem .treattablink {
    padding: 8px 20px !important;
    margin: 0px !important;
    border-radius: 0px !important;
    color: #fff !important;
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: 200;
}

.Header .deskdroppp .tabbmains .treattabitem .treattablink.active {
    font-size: 18px;
    background-color: #fff;
    color: #000 !important;
    font-weight: 400 !important;
    border-right: 7px solid #5b4020;
    font-family: Nunito Sans;
}
.Header .deskdroppp .tabbmains .treattabitem:hover .treattablink {
    font-size: 18px;
    background-color: #fff;
    color: #000 !important;
    font-weight: 400 !important;
    border-right: 7px solid #5b4020;
    font-family: Nunito Sans;
}

.Header .deskdroppp .droprows2 {
    padding: 50px 20px;
}
.Header #style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(176, 171, 171, 0.9);
    border-radius: 10px;
    background-color: white;
}

.Header #style-3::-webkit-scrollbar {
    width: 5px;
    background-color: #f6f6f6;
}

.Header #style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ab755c;
    margin-left: -30px;
    height: 2% !important;
}
.Header .scrollbar {
    width: 100%;
    height: 387px;
    overflow-y: scroll;
    overflow-x: hidden; /* Hide horizontal scrollbar */
}
.Header .droprows2 .scrollbar .text {
    color: black;
}

/***************** DropDown Css End *********************/

/* Mobile View Offcanvas */
.Mobile-Offcanvas .user-details .user-login {
    border-bottom: 1px solid #d7d7d7;
}

.Mobile-Offcanvas .nav-link {
    color: black !important;
    font-size: 15px;
}

.Mobile-Offcanvas .user-details .user-login .main {
    display: flex;
    justify-content: flex-start;
}

.Mobile-Offcanvas .user-details .user-login .main .circle {
    padding: 10px 15px;
    border: 1px solid #cbcbcb;
    height: 45px;
    width: 45px;
    border-radius: 100px;
}

.Mobile-Offcanvas .user-details .user-login .main .login .border-resign {
    height: 23px;
    padding-bottom: -18px;
    border: 1px solid #cfcfcf;
    margin: 1px 7px;
    padding: -5px 0px;
}

.Mobile-Offcanvas .user-details .user-login .main .login {
    display: flex;
    margin: 13px 20px;
}

.Mobile-Offcanvas .desktop-offcanvas.nav {
    display: block;
}
.Header .main-drop {
    padding: 2px 8px;
    width: 90px;
    height: 59px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #00000014;
}
.Header .drop-main {
    position: absolute;
    right: -1px;
    top: 47px;
}
.Mobile .logo-header img.logo {
    width: 100%;
    height: 100%;
}
.Mobile .logo-header {
    width: 63px;
    height: 39px;
}
.Mobile .main-logo {
    text-align: -webkit-center;
}
.Mobile-Offcanvas {
    display: none;
}
.Mobile  .accordion-item {
    border: none !important;
}
.Mobile-Offcanvas .accordheaderr .droprows1 {
    margin-bottom: 5%;
    background-color: #AB755C;
}
.Mobile-Offcanvas .nav-pills .nav-link.active {
    border-radius: 0px;
    background-color: #fff;
    border-right: 7px solid #5B4020;
    color: #000 !important;
   
}
.Mobile-Offcanvas p.treatname_text {
    font-size: 13px;
}
.Mobile-Offcanvas .nav-pills .nav-link {
    border-radius: 0px;
    color: white !important;
}
.Mobile-Offcanvas .accordion-button:not(.collapsed) {
    color: black !important;
    background-color: white !important;
    box-shadow: white !important;
}
.Mobile-Offcanvas .accordion-button:focus {
    border-color: none !important;
    box-shadow: none !important;
}
.Mobile-Offcanvas .accordion-item {
  
    border: none !important;
}
.Mobile-Offcanvas .droprows1 {
    background-color: #AB755C;
}
/*************************** media Query started *********************/
@media (min-width: 0px) and (max-width: 991px) {
    .Header .Desktop {
        display: none;
    }
    .Header .Mobile {
        display: block;
    }
    .Mobile-header {
        display: block;
    }
    .Header .main-header .sub-header .min-header {
        padding: 9px 20px;
    }
    .Header .main-header .sub-header .min-header .sub-text {
        font-size: 10px;
    }
    .Header .main-header.Mobile .main .main-header-content .main-logo {
        text-align: -webkit-center;
    }

    .Header .drop-main {
        right: -7px;
        top: 36px;
    }
    .Home-Banner {
        position: relative;
        top: 0px !important;
    }
    .Mobile-Offcanvas {
        display: none;
    }
    .Mobile-Offcanvas .droprows2.drppp {
        margin-top: 5%;
    }
}

@media (min-width: 0px) and (max-width: 320px) {
    .Header .min-header {
        padding: 9px 9px;
        display: block;
    }

    .Header .sub-text {
        font-size: 11px;
    }

    .Header a.nav-link {
        padding: 9px 12px;
        font-size: 13px;
    }
    .Header .main-header .main .main-header-content {
        margin-top: 5%;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .Header .min-header {
        padding: 9px 9px;
    }

    .Header .sub-text {
        font-size: 11px;
    }

    .Header a.nav-link {
        padding: 9px 12px;
        font-size: 13px;
    }
    .canva {
        margin: 4px 10px 0px 0px;
    }
    .Header .main-header .main .main-header-content {
        margin-top: 5%;
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .Header .min-header {
        padding: 9px 9px;
    }

    .Header .sub-text {
        font-size: 11px;
    }

    .Header a.nav-link {
        padding: 9px 12px;
        font-size: 13px;
    }
    .Header .main-header .main .main-header-content {
        margin-top: 3%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .Header .min-header {
        padding: 9px 9px;
    }

    .Header .sub-text {
        font-size: 11px;
    }

    .Header a.nav-link {
        padding: 9px 12px;
        font-size: 13px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .Header .main-header .main .main-header-content {
        margin-top: 0%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .Header .dropdown-menu.show {
        left: calc(50% - 406px) !important;
        max-width: 831px !important;
    }
   
}
