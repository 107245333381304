/* .ButtonMain .breadcrumb-item{
font-size: 12px;
} */
.ButtonMain li.breadcrumb-item a{
    font-size: 12px !important; 
    text-transform: uppercase;
    color: #000;
    font-weight: 500;
}



.ButtonMain .breadcrumb-item+.breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x);
    color: #24642E;
    font-weight: 700;
}


@media (min-width:0px) and (max-width:991px) {
    section.Bread-main {
        margin-top: 3%;
    }
}