.Login_Activity .Login_Activity_Form_Holder {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 3px #00000029;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    padding: 0px 30px 30px 30px;
}

.Login_Activity .Login_Activity_Form_Holder .log_in_form .edit_span_text_holder {
    width: fit-content;
    margin-left: auto;
    cursor: pointer;
}

.Login_Activity .Login_Activity_Form_Holder .log_in_form .edit_span_text {
    text-align: left;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 500;
    color: #24642e;
    cursor: pointer;
}
.Login .yes_no_btn_holder .text {
    color: black;
    cursor: pointer;
}
.Login .yes_no_btn_holder .icon {
    margin: 0px 11px;
}
.Login h1.mid-title {
    text-align: center;
    font-weight: 500;
}
.Login .btn-holder button.SubmitBtn {
    color: white;
    border: none;
    padding: 8px 86px;
}

.Login .btn-holder.yes_no_btn_holder {
    text-align: center;
    margin-top: 4%;
}
.Login .btn-holder button.YesBtn {
    color: white;
    border: none;
    padding: 8px 45px;
}
.Login_Activity.Modal-holder {
    margin-top: 4%;
    margin-bottom: 10%;
}
section.Login {
    margin-top: 1%;
}

@media (min-width: 0px) and (max-width: 1199px) {
    .Login_Activity button.SubmitBtn {
        padding: 3px 15px;
        font-size: 12px;
    }
  
}
@media (min-width: 0px) and (max-width:320px) {
    .Login .btn-holder button.SubmitBtn {
      padding: 8px 20px;
    }
    .Login .btn-holder button.YesBtn {
       padding: 8px 15px;
    }
}
@media (min-width: 321px) and (max-width: 484px) {
    .Login .btn-holder button.SubmitBtn {
        padding: 8px 37px;
    }
    .Login .btn-holder button.YesBtn {
        padding: 8px 21px;
        font-size: 12px;
    }
}
@media (min-width: 485px) and (max-width: 575px) {
    .Login .btn-holder button.SubmitBtn {
        padding: 8px 58px;
    }
    .Login .btn-holder button.YesBtn {
        padding: 8px 35px;
        font-size: 12px;
    }
}
@media (min-width: 576px) and (max-width: 1199px) {
    .Login .btn-holder button.SubmitBtn {
      padding: 8px 54px;
    }
    .Login .btn-holder button.YesBtn {
        padding: 6px 49px;
    }
}
