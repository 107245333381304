@import url("https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

body {
    margin: 0px;
    padding: 0px;
    /* font-family: "Nunito Sans" !important; */
}
@font-face {
    font-family: terms;
    src: url(../public/assests/fonts/gothamknights.ttf);
}
@font-face {
    font-family: myFont;
    src: url(../public/assests/fonts/LibreBaskerville-Regular.ttf);
}

@font-face {
    font-family: Nunito Sans;
    src: url(../public/assests/fonts/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf);
}
@font-face {
    font-family: italic;
    src: url(../public/assests/fonts/NunitoSans-Italic-VariableFont_YTLC\,opsz\,wdth\,wght.ttf);
}
a {
    color: #000 !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.sub-text {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    font-family: Nunito Sans;
    
}

.text {
    font-size: 14px;
    color: #ffffff;
    font-family: Nunito Sans;
}

.mid-title {
    font-size: 24px;
    color: #000000;
    font-family: myFont;
}

.title {
    font-family: myFont;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    opacity: 1;
}

.headingtext {
    font-family: myFont;
    color: #000;
    font-size: 40px;
    font-weight: 100;
}

.big-title {
    font-family: myFont;
    color: #222222;
    font-size: 50px;
    /* font-weight: bold; */
    opacity: 1;
}

.mini-text {
    font-size: 15px;
    color: #000000;
    font-family: Nunito Sans;
}

.mini-text-p {
    font-size: 16px;
    color: #000000;
    font-family: Nunito Sans;
}
.product-detail {
    font-size: 25px;
    font-weight: bold;
    font-family: Nunito Sans;
}
.product-text {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.price-text {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    font-family: Nunito Sans;
}
.text-feed {
    font-size: 16px;
    color: #222222;
    font-family: myFont;
}

li.breadcrumb-item {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    font-family: Nunito Sans;
}

.green{
    background: #56A462 0% 0% no-repeat padding-box;
border-radius: 5px;
}
.green:hover{
    background: #24642E 0% 0% no-repeat padding-box;
}
.brown{
    background: #AB755C 0% 0% no-repeat padding-box;
    border-radius: 5px;
}
.brown:hover{
    background: #5B4020 0% 0% no-repeat padding-box;
}
.breadcrumb-item+.breadcrumb-item  a  {
    color: #24642E !important;
    font-weight: 700;
    font-size: 12px;
}


.modal-backdrop {
   
    --bs-backdrop-bg: #0000008c !important;
  
}
.privacy{
    color: #2E2E2E;
    font-size: 20px;

}
@media (min-width: 0px) and (max-width: 320px) {
    .title {
        font-size: 18px;
    }

    .big-title {
        font-size: 25px;
    }

    .sub-text {
        font-size: 13px;
    }

    .mid-title {
        font-size: 16px;
    }

    .mini-text-p {
        font-size: 14px;
    }
    .headingtext {
        font-size: 26px;
    }
    .product-text {
        font-size: 13px;
    }
    .price-text {
        font-size: 16px;
    }
    .product-detail {
        font-size: 18px;
    }
    .mini-text {
        font-size: 12px;
    }
    .text {
        font-size: 12px;
      
    }
   
}

@media (min-width: 321px) and (max-width: 485px) {
    .title {
        font-size: 20px;
    }

    .big-title {
        font-size: 28px;
    }

    .sub-text {
        font-size: 14px;
    }

    .mid-title {
        font-size: 16px;
    }
    .price-text {
        font-size: 16px;
    }

    .mini-text-p {
        font-size: 14px;
    }
    .headingtext {
        font-size: 22px;
    }
    .product-text {
        font-size: 13px;
    }
    .product-detail {
        font-size: 18px;
    }
    .mini-text {
        font-size: 13px;
    }
    .text {
        font-size: 12px;
      
    }
}

@media (min-width: 486px) and (max-width: 575px) {
    .title {
        font-size: 24px;
    }

    .sub-text {
        font-size: 15px;
    }

    .big-title {
        font-size: 32px;
    }

    .mid-title {
        font-size: 18px;
    }

    .mini-text-p {
        font-size: 14px;
    }
    .headingtext {
        font-size: 28px;
    }
    .product-text {
        font-size: 14px;
    }
    .price-text {
        font-size: 18px;
    }
    .product-detail {
        font-size: 20px;
    }
    .mini-text {
        font-size: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .title {
        font-size: 28px;
    }

    .sub-text {
        font-size: 16px;
    }

    .big-title {
        font-size: 36px;
    }

    .mid-title {
        font-size: 18px;
    }
    .headingtext {
        font-size: 29px;
    }
    .product-text {
        font-size: 15px;
    }
    .price-text {
        font-size: 18px;
    }
    .product-detail {
        font-size: 22px;
    }
    .mini-text {
        font-size: 13px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .title {
        font-size: 30px;
    }

    .big-title {
        font-size: 40px;
    }

    .sub-text {
        font-size: 16px;
    }
    .price-text {
        font-size: 18px;
    }
    .mid-title {
        font-size: 20px;
    }
    .product-text {
        font-size: 16px;
    }
    .headingtext {
        font-size: 30px;
    }
    .product-detail {
        font-size: 22px;
    }
    .mini-text {
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .title {
        font-size: 30px;
    }

    .big-title {
        font-size: 30px;
    }

    .sub-text {
        font-size: 16px;
    }

    .mid-title {
        font-size: 20px;
    }
    .price-text {
        font-size: 16px;
    }
    .product-detail {
        font-size: 22px;
    }
}
