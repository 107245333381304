/* Blog css started */

.Blog .Recent-blogs h1.mid-title {
    margin: 79px 0px 33px 0px;
}

.Blog .Recent-blogs {
    display: flex;
}

.Blog .Recent-blogs .recent-border {
    margin-right: 40px;
    margin-top: 16%;
    border-left: 1px solid #b3b1b1;
}

.Blog .recent-feeds .blogs .blog-feed .content-blog img.feed {
    width: 100%;
    height: 100%;
}

.Blog .recent-feeds .blogs .blog-feed .content-blog {
    width: 98px;
    height: 98px;
}

.Blog .recent-feeds .blogs .blog-feed {
    display: flex;
    align-items: center;
}

.Blog .recent-feeds .blogs .blog-feed .feed-content .text {
    margin-bottom: 0px;
    color: #211f1f;
}

.Blog .recent-feeds .blogs .blog-feed .feed-content .text-feed {
    margin-bottom: 4px;
}

.Blog .recent-feeds .blogs {
    margin-bottom: 7%;
    padding: 6px 7px;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029, 0px 1px 6px #00000029;
    border-radius: 10px;
}

.Blog .recent-feeds .blogs .blog-feed .feed-content {
    margin: 2px 12px;
}

.Blog .blog-feed {
    display: flex;
    align-items: center;
}

.Blog .scrollbar {
    width: 100%;
    height: 387px;
    overflow-y: scroll;
}

.Blog .blogs-Main {
    top: 38px;
    left: 41px;
    position: relative;
}
.Blog .categories {
    margin-left: 48px;
}
.Blog .mid-title {
    margin-top: 22%;
    margin-bottom: 5%;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(176, 171, 171, 0.9);
    border-radius: 10px;
    background-color: white;
}

#style-3::-webkit-scrollbar {
    width: 5px;
    background-color: #f6f6f6;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: green;
    margin-left: -30px;
    height: 10px;
}

.Blog .icon {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    width: 100%;
    font-size: 22px;
}

.Blog .overlay {
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 40px;
    position: absolute;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ab755c45 41%, #ab755c 100%) 0% 0% no-repeat
        padding-box;
    border-radius: 0px 0px 10px 10px;
    right: 0px;
}

.Blog .categories .oils {
    display: flex;

}
.Blog  .Pagination ul.pagination {
    display: flex;
    margin-top: 1%;
    justify-content: center;
}
section.Blog {
    margin-bottom: 4%;
}
.Blog .oils .price-text {
  
    margin-right: 26px;
}
.Blog .categories .hover-one {
    transition: margin-left 0.3s ease, color 0.3s ease;
}

.Blog span.mini-text.main {
    font-weight: bold;
}

.Blog .categories .hover-one:hover {
    color: #24642e;
    margin-left: 11px;
}

.Blog .categories .hover-two:hover {
    border-bottom: 1px solid #24642e;
    color: #24642e;
}

.Blog .price-text {
    font-weight: bold;
}

/* Blog css End */

/************************************** Media Query ******************/

@media (min-width: 992px) and (max-width: 1199px) {
    .Blog .recent-feeds .blogs .blog-feed .content-blog {
        width: 82px;
        height: 76px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .Blog .blogs-Main {
      
        left: 0px;
       
      }
      .Blog .categories {
        margin-left: 0px;
    }
}
