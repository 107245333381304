.search-main{
    position: relative;
    }
     form.search {
        position: relative;
    }
     .search-main .icon {
        position: absolute;
        top: 9px;
        right: 18px;
    }
    .search-main .canva {
        margin: 4px 13px;
    }