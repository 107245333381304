.RelatedProducts .title {
    color: #222222;
    font-weight: 100;
    margin-bottom: 6%;
}
.RelatedProducts .swiper-pagination-lock {
    display: block;
}
section.RelatedProducts {
    margin-top: 5%;
    margin-bottom: 3%;
}
.RelatedProducts .swiper-pagination-bullet {
    height: 6px !important;
    border-radius: 4px !important;
    width: 13px !important;
    border-radius: 10px !important;
    opacity: 1;
    background-color: #5b4020;
}
.RelatedProducts span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 6px !important;
    border-radius: 10px !important;
    width: 26px !important;
    background-color: #24642e !important;
}
.RelatedProducts .swiper-pagination-fraction,
.RelatedProducts .swiper-pagination-custom,
.RelatedProducts .swiper-horizontal > .swiper-pagination-bullets,
.RelatedProducts .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 4px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}
