.Terms_And_Condition_Banner .Banner_holder {
    background-image: url(../../../../public/assests/Policybanner/terms.png);
    aspect-ratio: 1/0.2;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    /* align-items: center; */
  }
  .Terms_And_Condition_Banner .title {
    color: #222222;
     font-weight: normal;
}
.Terms_And_Condition_Banner .mini-text {
  color: #2E2E2E;
 
}
.Terms_And_Condition_Banner .text-holder {
  margin-top: 4%;
}
