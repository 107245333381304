/* CustomPagination.css */
.Pagination .pagination .page-item .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    color: #000000;
    font-family: "Nunito Sans";
    text-decoration: none;
    background-color: transparent !important;
    font-size: 17px;
}
.Pagination .page-item:first-child .page-link {
    border-top-left-radius: 100px !important;
    border-bottom-left-radius: 100px !important;
}
.Pagination .page-item:last-child .page-link {
    border-top-right-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
}
.Pagination .page-item:first-child .page-link {
    border: 1px solid #ab755c;
    border-radius: 100px;
    color: #ab755c !important;
    width: 35px;
    height: 35px;
    /* padding: 6px 0px; */
}
.Pagination .page-item:last-child .page-link {
    border: 1px solid #ab755c;
    border-radius: 100px;
    color: #ab755c !important;
    width: 35px;
    height: 35px;
}
.Pagination .page-link:focus {
    box-shadow: white !important;
}
.Pagination a.page-link {
    border: none;
}
.Pagination .pagination .page-item.active .page-link {
    background-color: #737577;
    border-color: #007bff;
    color: white;
}

.Pagination .pagination .page-item .page-link:hover {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.Pagination .pagination .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: black !important;
    border: none !important;
}
.Pagination li.page-item {
    margin: 0px 2px;
}
.Pagination ul.pagination {
    display: flex;
    margin-top: 4%;
    justify-content: center;
    align-items: center;
}

@media (min-width:0px) and (max-width:767px) {
    .Pagination .page-item:first-child .page-link {
       
        width: 25px;
        height: 25px;
        margin: 7px 0px;
    }
    .Pagination .page-item:last-child .page-link {
        width: 25px;
        height: 25px;
        margin: 7px 0px;
    }
    svg.svg-inline--fa.fa-arrow-left {
        font-size: 14px;
    }
    svg.svg-inline--fa.fa-arrow-right {
        font-size: 14px;
    }
    .Pagination .pagination .page-item .page-link {
      
        font-size: 15px;
    }
}