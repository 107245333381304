/* TrendingProducts css Started */

.TrendingProducts {
    margin-top: 6%;
    margin-bottom: 5%;
    padding: 4% 0px;
    /* height: 851px; */
    background: #f9f9f9 0% 0% no-repeat padding-box;
}
.TrendingProducts .text {
    position: relative;
    text-align: center;
    color: #24642e;
    padding: 0 20px;
}


.TrendingProducts p.text::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 19px;
    background-color: #24642e;
    bottom: 11px;
    margin-left: 10px;
}
.TrendingProducts p.text::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 19px;
    background-color: #24642e;
    bottom: 10px;
    margin-left: -30px;
}
.TrendingProducts h1.mid-title {
    text-align: center;
}
.TrendingProducts .Tab-Section .nav-pills .nav-link.active {
    font-size: 16px;
    color: #24642e !important;
    font-family: "Nunito Sans";
    font-weight: bold;
    background-color: transparent;
    position: relative; 
}

.TrendingProducts .Tab-Section .nav-pills .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%; 
    transform: translateX(-50%);
    border-width: 5px 5px 0 5px; 
    border-style: solid;
    border-color: #24642e transparent transparent transparent; 
}

.TrendingProducts .Tab-Section .nav-pills .nav-link {
    font-size: 16px;
    color: #000000;
    font-family: "Nunito Sans";
}
.TrendingProducts .Tab-Section .nav-link {
    padding: 1px 38px;
}
.TrendingProducts .Tab-Section {
    margin-top: 4%;
}
.TrendingProducts .Tab-Section .tab-content {
    margin-top: 3%;
}

/* TrendingProducts css End */


@media (min-width:0px) and (max-width:320px) {
    .TrendingProducts .Tab-Section .nav-pills .nav-link.active {
        font-size: 12px;
      
    }
    .TrendingProducts .Tab-Section .nav-pills .nav-link {
        font-size: 12px;
      
    }
    .TrendingProducts .Tab-Section .nav-link {
        padding: 1px 18px;
        width: 118px;
        margin: 5px -9px;
    }
    section.ProductCards {
        margin-bottom: 5%;
    }
 
}
@media (min-width:321px) and (max-width:485px) {
    .TrendingProducts .Tab-Section .nav-pills .nav-link.active {
        font-size: 12px;
      
    }
    .TrendingProducts .Tab-Section .nav-pills .nav-link {
        font-size: 12px;
      
    }
    .TrendingProducts .Tab-Section .nav-link {
        padding: 1px 18px;
        width: 118px;
        margin: 5px -9px;
    }
    section.ProductCards {
        margin-bottom: 5%;
    }
 
}
@media (min-width:486px) and (max-width:575px) {
    .TrendingProducts .Tab-Section .nav-pills .nav-link.active {
        font-size: 14px;
      
    }
    .TrendingProducts .Tab-Section .nav-pills .nav-link {
        font-size: 14px;
      
    }
    .TrendingProducts .Tab-Section .nav-link {
        padding: 1px 18px;
        width: 118px;
        margin: 5px -9px;
    }
    section.ProductCards {
        margin-bottom: 5% !important;
    }
 
}
@media (min-width:576px) and (max-width:991px) {
    .TrendingProducts .Tab-Section .nav-pills .nav-link.active {
        font-size: 14px;
      
    }
    .TrendingProducts .Tab-Section .nav-pills .nav-link {
        font-size: 14px;
      
    }
    .TrendingProducts .Tab-Section .nav-link {
        /* padding: 1px 18px; */
        /* width: 118px; */
        margin: 5px -9px;
    }
    section.ProductCards {
        margin-bottom: 5% !important;
    }
 
}