.Products {
    margin-bottom: 5%;
}
.products-card-section {
    padding-top: 18%;
    max-height: 943px;
    overflow-y: scroll;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}

.products-card-section::-webkit-scrollbar {
    display: none; 
}


/**************** Media Query started ***********************/
@media (min-width:0px) and  (max-width:991px) {
    .products-card-section {
        padding-top: 0%;
    }
  
}
