.Blog-Inner-Page .Blog-inner-content span.mini-text.main {
  font-weight: bold;
}
.Blog-Inner-Page .Blog-inner-content li {
  list-style-type: none;
  margin-bottom: 4%;
}
.Blog-Inner-Page ul {
  padding-left: 0px !important;
}
.Blog-Inner-Page .mid-title {
  font-weight: 600;
  margin: 36px 0px;
}
.Blog-Inner-Page .price-text {
  margin-bottom: 3%;
  font-weight: bold;
}
.Blog-Inner-Page span.mini-text.main {
  font-weight: bold;
}
.Blog-Inner-Page h1.price-text.intro {
  margin-bottom: 0%;
}

.Blog-Inner-Page .categories .oils {
  display: flex;
}

.Blog-Inner-Page .categories .price-text {
  margin-right: 26px;
}
.Blog-Inner-Page .categories .hover-one {
  transition: margin-left 0.3s ease, color 0.3s ease;
}

.Blog-Inner-Page .categories .hover-one:hover {
  margin-left: 10px;
  color: #24642e;
}
.Blog-Inner-Page .categories .hover-two:hover {
  border-bottom: 1px solid #24642e;
  color: #24642e;
}

.Blog-Inner-Page .image img.inner-image {
  height: 100%;
  width: 100%;
}
.Blog-Inner-Page .image {
  aspect-ratio: 4/2;
}

.Blog-Inner-Page .Recent-blogs h1.mid-title {
  margin: 79px 0px 33px 0px;
}

.Blog-Inner-Page .Recent-blogs {
  display: flex;
}

.Blog-Inner-Page .Recent-blogs .recent-border {
  margin-right: 40px;
  margin-top: 16%;
  border-left: 2px solid #b3b1b1;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed .content-blog img.feed {
  width: 100%;
  height: 100%;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed .content-blog {
  width: 98px;
  height: 98px;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed {
  display: flex;
  align-items: center;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed .feed-content .text {
  margin-bottom: 0px;
  color: #211f1f;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed .feed-content .text-feed {
  margin-bottom: 4px;
}

.Blog-Inner-Page .recent-feeds .blogs {
  margin-bottom: 7%;
  padding: 6px 7px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 6px #00000029, 0px 1px 6px #00000029;
  border-radius: 10px;
}

.Blog-Inner-Page .recent-feeds .blogs .blog-feed .feed-content {
  margin: 2px 12px;
}

.Blog-Inner-Page .blog-feed {
  display: flex;
  align-items: center;
}

.Blog-Inner-Page .scrollbar {
  width: 100%;
  height: 387px;
  overflow-y: scroll;
}

.Blog-Inner-Page .blogs-Main {
  top: 38px;
  left: 15px;
  position: relative;
}

.Blog-Inner-Page .categories {
  margin-left: 22px;
  margin-top: 18%;
}
.RecentBlogs p.price-text.hover-two {
  font-weight: 500;
}
.RecentBlogs p.price-text.hover-one {
  font-weight: 500;
}
.Blog-Inner-Page .Blog-Inner {
  margin: 38px 76px 0px 0px;
}
p.Blog-Inner-Page .price-text.hover-one {
  font-weight: 500;
}
#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(176, 171, 171, 0.9);
  border-radius: 10px;
  background-color: white;
}

#style-3::-webkit-scrollbar {
  width: 5px;
  background-color: #f6f6f6;
}

#style-3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: green;
  margin-left: -30px;
  height: 10px;
}

.Blog-Inner-Page .icon {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
  width: 100%;
  font-size: 22px;
}

.Blog-Inner-Page .overlay {
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 40px;
  position: absolute;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #ab755c45 41%, #ab755c 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  right: 0px;
}

.Blog-Inner-Page .categories .oils {
  display: flex;
}

.Blog-Inner-Page .categories .hover-one {
  transition: margin-left 0.3s ease, color 0.3s ease;
}

.Blog-Inner-Page span.mini-text.main {
  font-weight: bold;
}

.Blog-Inner-Page .categories .hover-one:hover {
  color: #24642e;
}

.Blog-Inner-Page .categories .hover-two:hover {
  border-bottom: 1px solid #24642e;
  color: #24642e;
}

.Blog-Inner-Page .price-text {
  font-weight: bold;
}

 .right-content-holder {
  display: block;
}
@media (min-width: 0px) and (max-width: 1199px) {
  .Blog-Inner-Page .Blog-Inner {
    margin: 40px 0px 0px 0px;
  }
  .Blog-Inner-Page .Recent-blogs .recent-border {
    margin-right: 0px;
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 991px) {
  .right-content-holder {
    display: flex;
    justify-content: space-between;
  }
  .Blog-Inner-Page .blogs-Main {
    top: 0px;
}

.Blog-Inner-Page .Main-Blog {
    margin-bottom: 5%;
}
}
