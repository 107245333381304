.Privacy_Policy_Content {
  margin-top: -3%;
  margin-bottom: 10%;
}
.Privacy_Policy_Content .Privacy_Policy_Content_holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 33px 62px;
  height: 906px;
  overflow-y: auto; /* Add vertical scrollbar if content exceeds height */
}

.Privacy_Policy_Content h3.privacy {
  margin-bottom: 2%;
}
.Privacy_Policy_Content
  .Privacy_Policy_Content_holder
  .list-holder
  .text-holder {
  margin: 40px 0px;
}

.Privacy_Policy_Content .sub-text {
  color: #2E2E2E;
}

@media (min-width: 0px) and (max-width: 991px) {
 
  .Privacy_Policy_Content {
    margin-top: 4%;
   
}
.Privacy_Policy_Content .Privacy_Policy_Content_holder {
  
  padding: 11px 32px;
}
}

