/* ProductCards css started */


.ProductCards .Product-Card .Product-Image img.card-images {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
}
.ProductCards .Product-Card:hover .Product-Image img.card-images {
    transform: scale(1.1);
}
.ProductCards .Product-Card .Product-Image{
    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 1/1;
}
section.ProductCards {
    margin-bottom: 15%;
}
.ProductCards .price-text {
    font-weight: bold;
}
.ProductCards  .Product-Card {
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 17px #00000017;
    border-radius: 15px;
}
.ProductCards:hover  .Product-Card {
    position: relative;
    background: #F2FFF4 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 17px #00000017;
    border-radius: 15px;
}
.ProductCards  .Product-Card .heart-icon {
    font-size: 29px;
    position: absolute;
    top: 12px;
    right: 17px;
}
.ProductCards  .Product-Card .content-product {
    margin: 16px 15px;
}

.ProductCards  .Product-Card .price-product {
    margin: 16px 15px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.ProductCards  .Product-Card .price-product .icon {
    font-size: 21px;
}
/* ProductCards css End */
