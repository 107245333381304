/* RecentBlogs css started */


 .Recent-blogs h1.mid-title {
    margin: 79px 0px 33px 0px;
}

 .Recent-blogs {
    display: flex;
}


 .Recent-blogs .recent-border {
    margin-right: 40px;
    margin-top: 16%;
    border-left: 3px solid #b3b1b1;
}

.Recent-blogs .recent-feeds .blogs .blog-feed .content-blog img.feed {
    width: 100%;
    height: 100%;
}

.Recent-blogs .recent-feeds .blogs .blog-feed .content-blog {
    width: 98px;
    height: 98px;
}

.Recent-blogs .recent-feeds .blogs .blog-feed {
    display: flex;
    align-items: center;
}

.Recent-blogs .recent-feeds .blogs .blog-feed .feed-content .text {
    margin-bottom: 0px;
    color: #211f1f;
}

.Recent-blogs .recent-feeds .blogs .blog-feed .feed-content .text-feed {
    margin-bottom: 4px;
}

.Recent-blogs .recent-feeds .blogs {
    margin-bottom: 7%;
    padding: 6px 7px;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #00000029, 0px 1px 6px #00000029;
    border-radius: 10px;
}

.Recent-blogs .recent-feeds .blogs .blog-feed .feed-content {
    margin: 2px 12px;
}

.Recent-blogs .blog-feed {
    display: flex;
    align-items: center;
}

.Recent-blogs .scrollbar {
    width: 100%;
    height: 387px;
    overflow-y: scroll;
}

.Recent-blogs .blogs-Main {
    top: 38px;
    left: 15px;
    position: relative;
}

.Recent-blogs .categories {
    margin-left: 22px;
}

.Recent-blogs .mid-title {
    margin-top: 15%;
    margin-bottom: 5%;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(176, 171, 171, 0.9);
    border-radius: 10px;
    background-color: white;
}

#style-3::-webkit-scrollbar {
    width: 5px;
    background-color: #f6f6f6;
}

#style-3::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: green;
    margin-left: -30px;
    height: 10px;
}

.Recent-blogs .icon {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0px;
    right: 0px;
    width: 100%;
    font-size: 22px;
}

.Recent-blogs.overlay {
    left: 0px;
    bottom: 2px;
    width: 100%;
    height: 40px;
    position: absolute;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ab755c45 41%, #ab755c 100%) 0% 0% no-repeat
        padding-box;
    border-radius: 0px 0px 10px 10px;
    right: 0px;
}

.Recent-blogs .categories .oils {
    display: flex;
}

.Recent-blogs.categories .hover-one {
    transition: margin-left 0.3s ease, color 0.3s ease;
}

.Recent-blogs span.mini-text.main {
    font-weight: bold;
}

.Recent-blogs .categories .hover-one:hover {
    color: #24642e;
}

.Recent-blogs .categories .hover-two:hover {
    border-bottom: 1px solid #24642e;
    color: #24642e;
}

.Blog .price-text {
    font-weight: bold;
}

  
  
/* RecentBlogs css End */


/************************************** Media Query ******************/

@media (min-width:992px) and (max-width:1199px) {
    .RecentBlogs   .recent-feeds .RecentBlogs  s .RecentBlogs  -feed .content-blog {
        width: 82px;
        height: 76px;
    }
}

@media (min-width:0px) and (max-width:991px) {
.RecentBlogs {
    margin-bottom: 5%;
}
}