.Privacy_Policy_Banner .Banner_holder {
  background-image: url(../../../../public/assests/Policybanner/privacy.png);
  aspect-ratio: 1/0.2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  /* align-items: center; */
}

.Privacy_Policy_Banner .title {
  color: #222222;
   font-weight: 500;
}
.Privacy_Policy_Banner .text-holder {
  margin-top: 4%;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
    font-size: 20px;
  }


}

@media (min-width: 321px) and (max-width: 485px) {
  .Privacy_Policy .Privacy_Policy_Banner .Banner_holder .text-holder h1 {
    font-size: 22px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
    font-size: 25px;
  }
}

@media (min-width: 576px) and (max-width: 765px) {
  .Privacy_Policy_Banner .Banner_holder .text-holder h1 {
    font-size: 30px;
  }
}

@media (min-width: 766px) and (max-width: 991px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
    font-size: 35px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
    font-size: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Privacy_Policy .Banner_holder .text-holder h1 {
    font-size: 50px;
  }
}