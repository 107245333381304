.SellerStepMain .RSPBprogressBar {
    top: 60px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    background-color: #5b4020 !important;
}

.SellerStepMain .SellerStepMain {
    /* background: #f9fbff 0% 0% no-repeat padding-box; */
    padding: 0% 1% 1% 0%;
}

.SellerStepMain .RSPBprogressBar {
    top: 60px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    background-color: #5b4020 !important;
}

.SellerStepMain .SellerStepMain {
    /* background: #f9fbff 0% 0% no-repeat padding-box; */
    padding: 0% 1% 1% 0%;
}

.SellerStepMain .left-goto-homee,
.SellerStepMain .left-goto-homee:hover {
    color: #000;
    font-size: 15px;
}

.SellerStepMain .child-subcat-listtt li {
    list-style: none;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}

.SellerStepMain .bankk-verrr {
    position: relative;
}

.SellerStepMain .bankk-verrr .verrrr-bttt {
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: #1c42a9;
    color: #fff;
    font-size: 11px;
    border: 0px;
    border-radius: 2px;
}

.SellerStepMain .child-subcat-listtt li.selected {
    color: #1c42a9;
}

.SellerStepMain .bd-underr {
    position: relative;
}

.SellerStepMain .bd-underr::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #ccc;
    height: 30px;
    z-index: 999999999999 !important;
    display: block;
    top: 19px;
}

.SellerStepMain .bd-underr::after {
    content: "";
    position: absolute;
    width: 22px;
    height: 1px;
    background: #ccc;
    left: 0px;
    top: 48px;
}

.SellerStepMain .tri-shap {
    content: "";
    position: absolute;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid #ccc;
    border-bottom: 5px solid transparent;
    top: 43px;
    left: 20px;
}

.SellerStepMain .ProgressBar .step {
    align-items: center;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 50%;
    color: #562278;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    height: 44px;
    font-family: "Poppins";
    justify-content: center;
    width: 44px;
}

.SellerStepMain .ProgressBar .step.completed {
    background-color: #664de5;
}

.SellerStepMain .userForm {
    height: 100%;
    padding: 4% 0% 0% 0%;
    position: relative;
    /* margin-bottom: 15%; */
}

.SellerStepMain .overviewMain {
    position: relative;
}

.SellerStepMain .overviewMain .SaveAndNext {
    position: absolute;
    bottom: -64px;
    left: 0;
    right: 0;
}

.SellerStepMain .userForm .SaveAndNext {
    position: absolute;
    bottom: -64px;
    left: 0;
    right: 0;
}

.SellerStepMain .personal-details-form .categoriesList .selected {
    color: #1c42a9;
    font-weight: 600;
}
.SellerStepMain .userForm .apply_now_btn .tabs-btn {
    padding: 7px 20px;
    border: none;
}


.SellerStepMain .apply_now_btn .back-btn {

    padding: 8px 41px;
    border: none;
}

.SellerStepMain .userForm .apply_now_btn {
    padding: 7px 22px;
    /* position: absolute; */
    bottom: 51px;
    right: 30px;
    margin-top: 25px;
    cursor: pointer;
}

.SellerStepMain .userForm .apply_now_btn p {
    font-size: 11px;
}

.SellerStepMain .personal-details-form .categoriesList .all-active {
    color: #1c42a9;
    font-weight: 600;
}

.SellerStepMain .userForm .apply_now_btn p span {
    color: #1c42a9;
    margin-left: 4px;
    font-weight: 500;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder {
    border-radius: 10px 10px 0px 0px;
    padding: 1% 10px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    color: #131313;
    margin-bottom: 0px;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .heading-holder h6 {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    color: #131313;
    margin-bottom: 0px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder {
    display: flex;
    justify-content: start;
    align-items: center;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead .left-arrow {
    margin-right: 14px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder {
    text-align: left;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder span {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    color: #131313;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .span-holder {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .span-holder {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .span-holder span {
    /* text-decoration: underline; */
    font-size: 14px;
    font-weight: 500;
    color: #131313;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 13px;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .span-holder span {
    /* text-decoration: underline; */
    font-size: 14px;
    font-weight: 500;
    color: #131313;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 13px;
}

.SellerStepMain .SellerStepMain .go-to-home {
    position: relative;
}

.SellerStepMain .go-to-home .go-to-home-text {
    text-align: center;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

.SellerStepMain .go-to-home .go-to-home-text .left-angle-arrow {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    margin-right: 5px;
}

.SellerStepMain
    .userForm
    .personal-details-form
    .form-header-holder
    .span-holder
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #1967d3;
}

.SellerStepMain
    .userForm
    .personal-details-form
    .form-header-holder
    .span-holder
    .CircularProgressbar
    .CircularProgressbar-text {
    font-size: 25px;
    dominant-baseline: middle;
    color: #131313 !important;
    text-anchor: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    padding: 5px 10px;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder h1 {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder p {
    font-size: 12px;
    font-weight: 500;
    color: #646464;
    margin-bottom: 5px;
}

.SellerStepMain .userForm .Employment_Details_award_form {
    margin-top: 5%;
}

.SellerStepMain .userForm .Employment_Details_award_form .personal-details-form .details-form-holder {
    padding: 2% 2% 5% 2%;
}

.SellerStepMain .userForm .employee-details-step .details-form-holder {
    padding: 2% 2% 5% 2%;
}

.SellerStepMain .details-form-holder .radio-text-content {
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}

.SellerStepMain .personal-details-form .Add-education-btn-holder .apply-btn-holder {
    text-align: end;
}

.SellerStepMain .userForm .personal-details-form .react-tel-input .form-control {
    width: 100%;
    margin-bottom: 23px !important;
}

.SellerStepMain .userForm .education-card {
    margin-top: 5%;
}

.SellerStepMain .userForm .education-card .card-holder h6 {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.SellerStepMain .userForm .education-card .card-holder .card-info-holder h4 {
    text-align: left;
    font-size: 10px;
    font-weight: 500;
    color: #131313;
}

.SellerStepMain .userForm .education-card .card-holder .card-info-holder {
    background: #fff9f2 0% 0% no-repeat padding-box;
    border: 1px solid #ee9723;
    border-radius: 5px;
    padding: 5px 5px;
    height: 100px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dbdbdb;
    border-radius: 19px;
    margin-bottom: 25px;
    /* position: relative; */
}

.SellerStepMain .personal-details-form .stepthreesearch {
    position: relative;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .searchStepIon {
    position: absolute;
    top: 10px;
    right: 9px;
    font-size: 16px;
    color: #9b9b9b;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead h3 {
    font-size: 16px;
    margin-bottom: 25px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 15px;
    font-weight: 600;
}

.SellerStepMain .personal-details-form {
    padding: 2%;
    margin-bottom: 6%;
    margin-top: 2%;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 34px #00000012;
}

.SellerStepMain .personal-details-form .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    overflow-y: auto;
    max-height: 350px;
    padding-bottom: 39px;
    padding-right: 20px;
}

.SellerStepMain .personal-details-form .box {
    padding: 20px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    font-size: 14px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .categoriesList {
    overflow-y: auto;
    max-height: 350px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .categoriesList p {
    font-size: 14px;
    cursor: pointer;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar {
    width: 5px;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar-thumb {
    background: #888;
}

.SellerStepMain .personal-details-form .stepsixform .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #ccc;
    border-radius: 5px;
}

.SellerStepMain .personal-details-form .form-label {
    font-size: 14px;
    font-weight: 500;
}

.SellerStepMain .personal-details-form .form-control {
    font-size: 14px !important;
    border-radius: 5px;
    margin-bottom: 23px !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
    border-radius: 0;
}

.SellerStepMain .buisness__control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
    border-radius: 0;
}

.SellerStepMain .personal-details-form .get-otp-text {
    position: relative;
}

.SellerStepMain .personal-details-form .get-otp-text .telepara {
    position: absolute;
    top: 8px;
    right: 35px;
    font-size: 12px;
    color: #1c42a9;
    font-weight: 600;
    cursor: pointer;
}

.SellerStepMain .personal-details-form .StepsixPassword {
    position: relative;
}

.SellerStepMain .personal-details-form .StepsixPassword .StepsixeditIcon {
    position: absolute;
    top: 9px;
    right: 7px;
    color: #a1a1a1;
    font-size: 15px;
}

.SellerStepMain .personal-details-form .field-bottom .emailmainsec {
    position: relative;
}

.SellerStepMain .personal-details-form .field-bottom {
    margin-bottom: 13px;
}

.SellerStepMain .personal-details-form .inputFields .form-control {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    background: transparent;
    margin-left: 10px;
    width: 40px;
    text-align: center;
}

.SellerStepMain .personal-details-form .inputFields {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.SellerStepMain .personal-details-form .inputFields .verifyBtn {
    padding: 2px 10px;
    margin-left: 5px;
    background: #ff923d;
    border: 0px;
    font-size: 13px;
}

.SellerStepMain .personal-details-form .inputFields .resendbtnnn {
    border: 0px;
    background-color: transparent;
    color: #000000;
    font-size: 13px;
}

.SellerStepMain .personal-details-form .inputFields .otp-timeset {
    font-size: 12px;
    color: #000;
}

.SellerStepMain .personal-details-form .details-form-holder .verified {
    position: relative;
}

.SellerStepMain .personal-details-form .details-form-holder .verified span {
    display: flex;
    position: absolute;
    top: 0;
    right: 8px;
    justify-content: end;
    align-items: center;
    height: 100%;
}

.SellerStepMain .personal-details-form .details-form-holder .verified .verified-text {
    font-size: 15px;
    font-weight: 500;
    color: green;
}

.SellerStepMain .personal-details-form .details-form-holder .verified .not-verified-text {
    font-size: 15px;
    font-weight: 500;
    color: red;
}

.SellerStepMain .personal-details-form .gstMainbtn {
    position: relative;
}

.SellerStepMain .personal-details-form .stepsevenMain .imarkcontent {
    background-color: #e9f3ff;
    border: 1px solid #0f3768;
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepsevenMain .moubtn {
    background-color: #e9f3ff;
    border: 1px solid #0f3768;
    font-size: 13px;
    border-radius: 3px;
    color: #000;
    padding: 5px 20px;
    margin-bottom: 12px;
}

.SellerStepMain .personal-details-form .stepsevenMain .readMoumain p {
    font-weight: 600;
}

.SellerStepMain .personal-details-form .stepsevenMain .readMoumain {
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepsevenMain .getotptxt {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translate(0%, -50%);
    color: red;
}

.SellerStepMain .personal-details-form .stepsevenMain .getotpinput .form-control {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: center;
}

.SellerStepMain .personal-details-form .stepeight .stepeightbtn button {
    background-color: #ebf4ff;
    font-size: 13px;
    border-radius: 3px;
    color: #000;
    padding: 5px 20px;
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepeight .notifytxt {
    font-size: 13px;
}

.SellerStepMain .personal-details-form .stepeight .circleCheckicon {
    font-size: 18px;
}

.SellerStepMain .personal-details-form .packaginghead h3 {
    font-size: 16px;
    font-weight: 600;
}

.SellerStepMain .personal-details-form .packaginghead p {
    font-size: 16px;
}

.SellerStepMain .personal-details-form .note {
    display: flex;
}

.SellerStepMain .personal-details-form .field-bottom .validate {
    font-size: 13px;
    color: red;
}

.SellerStepMain .personal-details-form .trianglealertmark {
    font-size: 35px;
}

.SellerStepMain .personal-details-form .get-otp-text label {
    font-size: 14px;
}

.SellerStepMain .personal-details-form .addresstxt {
    font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .SellerStepMain .RSPBprogressBar {
        top: 35px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .SellerStepMain .userForm .apply_now_btn {
        display: flex;
    }
    .SellerStepMain .apply_now_btn .back-btn {
        padding: 8px 27px;
    }
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
        margin-right: 35px;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .overviewMain .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 10px;
        height: 20px;
        width: 20px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 2% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    /* .SellerStepMain .userForm .apply_now_btn .tabs-btn {
        margin: 5px 0px;
        margin-left: 0px !important;
        padding: 4px 22px;
        font-size: 12px;
    } */

    /* .SellerStepMain .apply_now_btn .back-btn {
        font-size: 12px;
        padding: 5px 22px;
        margin-right: 10px;
    } */

    .SellerStepMain .userForm .apply_now_btn {
        padding: 7px 13px;
    }

    .SellerStepMain .left-goto-homee,
    .SellerStepMain .left-goto-homee:hover {
        font-size: 11px;
    }

    .SellerStepMain .personal-details-form .form-control {
        font-size: 12px !important;
    }

    .SellerStepMain .personal-details-form .get-otp-text .telepara {
        top: 10px;
        right: 11px;
        font-size: 11px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    /* .SellerStepMain .userForm .apply_now_btn .tabs-btn {
        margin: 5px 0px;
        margin-left: 0px !important;
        padding: 4px 3px;
        font-size: 12px;
    } */

    /* .SellerStepMain .apply_now_btn .back-btn {
        font-size: 12px;
        padding: 5px 10px;
        margin-right: 10px;
    } */

    .SellerStepMain .userForm .apply_now_btn {
        padding: 7px 13px;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
        margin-right: 21px;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 12px;
        height: 25px;
        width: 25px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 2% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    .SellerStepMain .left-goto-homee,
    .SellerStepMain .left-goto-homee:hover {
        font-size: 13px;
    }

    .SellerStepMain .personal-details-form .form-control {
        font-size: 12px !important;
    }

    .SellerStepMain .personal-details-form .get-otp-text .telepara {
        top: 10px;
        right: 11px;
        font-size: 11px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        margin-top: 5%;
        justify-content: center;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    .SellerStepMain .userForm .apply_now_btn .tabs-btn {
        margin-left: 0 !important;
    }
}

@media (min-width: 577px) and (max-width: 678px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    /* .SellerStepMain .apply_now_btn .back-btn {
        padding: 5px 33px !important;
    } */

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    .SellerStepMain .userForm .apply_now_btn .tabs-btn {
        margin-left: 0 !important;
    }
}

@media (min-width: 679px) and (max-width: 567px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}

@media (min-width: 679px) and (max-width: 767px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .ProgressBar .step-name {
        bottom: -30px;
        font-size: 10px;
        line-height: 12px;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}

@media (min-width: 1201px) and (max-width: 1400px) {
    .SellerStepMain .RSPBprogressBar {
        top: 30px;
        width: 100%;
    }

    /* .SellerStepMain .userForm {
      padding: 25% 0% 0% 0%;
    } */
}

.SellerStepMain .left-goto-homee,
.SellerStepMain .left-goto-homee:hover {
    color: #000;
    font-size: 15px;
}

.SellerStepMain .child-subcat-listtt li {
    list-style: none;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}

.SellerStepMain .bankk-verrr {
    position: relative;
}

.SellerStepMain .bankk-verrr .verrrr-bttt {
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: #5b4020;
    color: #fff;
    font-size: 11px;
    border: 0px;
    border-radius: 2px;
}

.SellerStepMain .child-subcat-listtt li.selected {
    color: #5b4020;
}

.SellerStepMain .bd-underr {
    position: relative;
}

.SellerStepMain .bd-underr::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #ccc;
    height: 30px;
    z-index: 999999999999 !important;
    display: block;
    top: 19px;
}

.SellerStepMain .bd-underr::after {
    content: "";
    position: absolute;
    width: 22px;
    height: 1px;
    background: #ccc;
    left: 0px;
    top: 48px;
}

.SellerStepMain .tri-shap {
    content: "";
    position: absolute;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid #ccc;
    border-bottom: 5px solid transparent;
    top: 43px;
    left: 20px;
}

.SellerStepMain .ProgressBar .step {
    align-items: center;

    border: 1px solid #707070;
    border-radius: 50%;
    color: #562278;
    display: flex;
    font-size: 18px;
    font-weight: 500;

    font-family: "Poppins";
    justify-content: center;

    width: 52px;
    height: 52px;

    border: 1px solid #5b4020 !important;
}

.SellerStepMain .ProgressBar .step.completed {
    background-color: #664de5;
}

section.SellerStepMain {
    margin-top: 4%;
}
.SellerStepMain .RSPBstep {
    margin: 0px 100px;
}
.SellerStepMain .userForm {
    height: 100%;
    padding: 6% 0% 10% 0%;
    position: relative;
    /* margin-bottom: 15%; */
}

.SellerStepMain .overviewMain {
    position: relative;
}

.SellerStepMain .overviewMain .SaveAndNext {
    position: absolute;
    bottom: -64px;
    left: 0;
    right: 0;
}

.SellerStepMain .userForm .SaveAndNext {
    position: absolute;
    bottom: -64px;
    left: 0;
    right: 0;
}

.SellerStepMain .ProgressBar .step-name {
    font-weight: bold;
    margin-top: 78px;
    /* top: 1px; */
    position: absolute;
    /* bottom: -65px; */
    text-align: center;
    /* color: #5b4020; */
    line-height: 19px;
}

.SellerStepMain .personal-details-form .categoriesList .selected {
    color: #1c42a9;
    font-weight: 600;
}

/* .SellerStepMain .apply_now_btn .back-btn {
    background: #dfdfdf 0% 0% no-repeat padding-box;
    border: 0px;
    color: #000000;
    font-size: 14px;
    padding: 5px 25px;
    box-shadow: none;
} */

.SellerStepMain .userForm .apply_now_btn {
  text-align: end;
  padding: 7px 22px;
  /* position: absolute; */
  bottom: 51px;
  right: 30px;
  margin-top: 25px;
  cursor: pointer;
}

.SellerStepMain .userForm .apply_now_btn p {
    font-size: 11px;
}

.SellerStepMain .personal-details-form .categoriesList .all-active {
    color: #1c42a9;
    font-weight: 600;
}

.SellerStepMain .userForm .apply_now_btn p span {
    color: #1c42a9;
    margin-left: 4px;
    font-weight: 500;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder {
    border-radius: 10px 10px 0px 0px;
    padding: 1% 10px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    color: #131313;
    margin-bottom: 0px;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .heading-holder h6 {
    text-align: start;
    font-size: 24px;
    font-weight: 600;
    color: #131313;
    margin-bottom: 0px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder {
    display: flex;
    justify-content: start;
    align-items: center;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead .left-arrow {
    margin-right: 14px;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder {
    text-align: left;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .Back-span-holder span {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    color: #131313;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .span-holder {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .span-holder {
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.SellerStepMain .userForm .personal-details-form .form-header-holder .span-holder span {
    /* text-decoration: underline; */
    font-size: 14px;
    font-weight: 500;
    color: #131313;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 13px;
}

.SellerStepMain .overviewMain .personal-details-form .form-header-holder .span-holder span {
    /* text-decoration: underline; */
    font-size: 14px;
    font-weight: 500;
    color: #131313;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 13px;
}

.SellerStepMain .SellerStepMain .go-to-home {
    position: relative;
}

.SellerStepMain .go-to-home .go-to-home-text {
    text-align: center;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

.SellerStepMain .go-to-home .go-to-home-text .left-angle-arrow {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    margin-right: 5px;
}

.SellerStepMain
    .userForm
    .personal-details-form
    .form-header-holder
    .span-holder
    .CircularProgressbar
    .CircularProgressbar-path {
    stroke: #1967d3;
}

.SellerStepMain
    .userForm
    .personal-details-form
    .form-header-holder
    .span-holder
    .CircularProgressbar
    .CircularProgressbar-text {
    font-size: 25px;
    dominant-baseline: middle;
    color: #131313 !important;
    text-anchor: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #b5b5b5;
    border-radius: 5px;
    padding: 5px 10px;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder h1 {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}

.SellerStepMain .personal-details-form .Percentages-Add-Education .previous-job-details .job-content-holder p {
    font-size: 12px;
    font-weight: 500;
    color: #646464;
    margin-bottom: 5px;
}

.SellerStepMain .userForm .Employment_Details_award_form {
    margin-top: 5%;
}

.SellerStepMain .userForm .Employment_Details_award_form .personal-details-form .details-form-holder {
    padding: 2% 2% 5% 2%;
}

.SellerStepMain .userForm .employee-details-step .details-form-holder {
    padding: 2% 2% 5% 2%;
}

.SellerStepMain .details-form-holder .radio-text-content {
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}

.SellerStepMain .personal-details-form .Add-education-btn-holder .apply-btn-holder {
    text-align: end;
}

.SellerStepMain .userForm .personal-details-form .react-tel-input .form-control {
    width: 100%;
    margin-bottom: 23px !important;
}

.SellerStepMain .userForm .education-card {
    margin-top: 5%;
}

.SellerStepMain .userForm .education-card .card-holder h6 {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.SellerStepMain .userForm .education-card .card-holder .card-info-holder h4 {
    text-align: left;
    font-size: 10px;
    font-weight: 500;
    color: #131313;
}

.SellerStepMain .userForm .education-card .card-holder .card-info-holder {
    background: #fff9f2 0% 0% no-repeat padding-box;
    border: 1px solid #ee9723;
    border-radius: 5px;
    padding: 5px 5px;
    height: 100px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dbdbdb;
    border-radius: 19px;
    margin-bottom: 25px;
    /* position: relative; */
}

.SellerStepMain .personal-details-form .stepthreesearch {
    position: relative;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .searchStepIon {
    position: absolute;
    top: 10px;
    right: 9px;
    font-size: 16px;
    color: #9b9b9b;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .CategoriesHead h3 {
    font-size: 16px;
    margin-bottom: 25px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 15px;
    font-weight: 600;
}

.SellerStepMain .personal-details-form {
    padding: 2%;
    margin-bottom: 6%;
    margin-top: 2%;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 34px #00000012;
}

.SellerStepMain .personal-details-form .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    overflow-y: auto;
    max-height: 350px;
    padding-bottom: 39px;
    padding-right: 20px;
}

.SellerStepMain .personal-details-form .box {
    padding: 20px;
    text-align: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    font-size: 14px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .categoriesList {
    overflow-y: auto;
    max-height: 350px;
}

.SellerStepMain .personal-details-form .stepthreeformRowmain .categoriesList p {
    font-size: 14px;
    cursor: pointer;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar {
    width: 5px;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.SellerStepMain .personal-details-form ::-webkit-scrollbar-thumb {
    background: #888;
}

.SellerStepMain .personal-details-form .stepsixform .form-control {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #ccc;
    border-radius: 5px;
}

.SellerStepMain .personal-details-form .form-label {
    font-size: 14px;
    font-weight: 500;
}

.SellerStepMain .personal-details-form .form-control {
    font-size: 14px !important;
    border-radius: 5px;
    margin-bottom: 23px !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
    border-radius: 0;
}

.SellerStepMain .buisness__control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
    border-radius: 0;
}

.SellerStepMain .personal-details-form .get-otp-text {
    position: relative;
}

.SellerStepMain .personal-details-form .get-otp-text .telepara {
    position: absolute;
    top: 8px;
    right: 35px;
    font-size: 12px;
    color: #1c42a9;
    font-weight: 600;
    cursor: pointer;
}

.SellerStepMain .personal-details-form .StepsixPassword {
    position: relative;
}

.SellerStepMain .personal-details-form .StepsixPassword .StepsixeditIcon {
    position: absolute;
    top: 9px;
    right: 7px;
    color: #a1a1a1;
    font-size: 15px;
}

.SellerStepMain .personal-details-form .field-bottom .emailmainsec {
    position: relative;
}

.SellerStepMain .personal-details-form .field-bottom {
    margin-bottom: 13px;
}

.SellerStepMain .personal-details-form .inputFields .form-control {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    background: transparent;
    margin-left: 10px;
    width: 40px;
    text-align: center;
}

.SellerStepMain .personal-details-form .inputFields {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.SellerStepMain .personal-details-form .inputFields .verifyBtn {
    padding: 2px 10px;
    margin-left: 5px;
    background: #ff923d;
    border: 0px;
    font-size: 13px;
}

.SellerStepMain .personal-details-form .inputFields .resendbtnnn {
    border: 0px;
    background-color: transparent;
    color: #000000;
    font-size: 13px;
}

.SellerStepMain .personal-details-form .inputFields .otp-timeset {
    font-size: 12px;
    color: #000;
}

.SellerStepMain .personal-details-form .details-form-holder .verified {
    position: relative;
}

.SellerStepMain .personal-details-form .details-form-holder .verified span {
    display: flex;
    position: absolute;
    top: 0;
    right: 8px;
    justify-content: end;
    align-items: center;
    height: 100%;
}

.SellerStepMain .personal-details-form .details-form-holder .verified .verified-text {
    font-size: 15px;
    font-weight: 500;
    color: green;
}

.SellerStepMain .personal-details-form .details-form-holder .verified .not-verified-text {
    font-size: 15px;
    font-weight: 500;
    color: red;
}

.SellerStepMain .personal-details-form .gstMainbtn {
    position: relative;
}

.SellerStepMain .personal-details-form .stepsevenMain .imarkcontent {
    background-color: #e9f3ff;
    border: 1px solid #0f3768;
    padding: 5px;
    font-size: 13px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepsevenMain .moubtn {
    background-color: #e9f3ff;
    border: 1px solid #0f3768;
    font-size: 13px;
    border-radius: 3px;
    color: #000;
    padding: 5px 20px;
    margin-bottom: 12px;
}

.SellerStepMain .personal-details-form .stepsevenMain .readMoumain p {
    font-weight: 600;
}

.SellerStepMain .personal-details-form .stepsevenMain .readMoumain {
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepsevenMain .getotptxt {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translate(0%, -50%);
    color: red;
}

.SellerStepMain .personal-details-form .stepsevenMain .getotpinput .form-control {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: center;
}

.SellerStepMain .personal-details-form .stepeight .stepeightbtn button {
    background-color: #ebf4ff;
    font-size: 13px;
    border-radius: 3px;
    color: #000;
    padding: 5px 20px;
    margin-bottom: 15px;
}

.SellerStepMain .personal-details-form .stepeight .notifytxt {
    font-size: 13px;
}

.SellerStepMain .personal-details-form .stepeight .circleCheckicon {
    font-size: 18px;
}

.SellerStepMain .personal-details-form .packaginghead h3 {
    font-size: 16px;
    font-weight: 600;
}

.SellerStepMain .personal-details-form .packaginghead p {
    font-size: 16px;
}

.SellerStepMain .personal-details-form .note {
    display: flex;
}

.SellerStepMain .personal-details-form .field-bottom .validate {
    font-size: 13px;
    color: red;
}

.SellerStepMain .personal-details-form .trianglealertmark {
    font-size: 35px;
}

.SellerStepMain .personal-details-form .get-otp-text label {
    font-size: 14px;
}

.SellerStepMain .personal-details-form .addresstxt {
    font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .SellerStepMain .RSPBprogressBar {
        top: 35px;
    }
}

@media (min-width: 280px) and (max-width: 320px) {
    .SellerStepMain .RSPBstep {
        margin: 0px 25px;
    }
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
        margin-right: 35px;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .overviewMain .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 10px;
        height: 20px;
        width: 20px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 2% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    /* .SellerStepMain .apply_now_btn .back-btn {
        font-size: 12px;
        padding: 5px 22px;
        margin-right: 10px;
    } */

    .SellerStepMain .userForm .apply_now_btn {
        padding: 7px 13px;
    }

    .SellerStepMain .left-goto-homee,
    .SellerStepMain .left-goto-homee:hover {
        font-size: 11px;
    }

    .SellerStepMain .personal-details-form .form-control {
        font-size: 12px !important;
    }

    .SellerStepMain .personal-details-form .get-otp-text .telepara {
        top: 10px;
        right: 11px;
        font-size: 11px;
    }
}

@media (min-width: 321px) and (max-width: 485px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 3% 0%;
    }
    .SellerStepMain .RSPBstep {
      margin: 0px 25px;
  }
    /* .SellerStepMain .apply_now_btn .back-btn {
        font-size: 12px;
        padding: 5px 10px;
        margin-right: 10px;
    } */

    .SellerStepMain .userForm .apply_now_btn {
        padding: 7px 13px;
        display: flex;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick .form-check {
        margin-right: 21px;
    }
    .SellerStepMain .apply_now_btn .back-btn {
     
      padding: 8px 29px;
    
  }
    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 12px;
        height: 25px;
        width: 25px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 2% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    .SellerStepMain .left-goto-homee,
    .SellerStepMain .left-goto-homee:hover {
        font-size: 13px;
    }

    .SellerStepMain .personal-details-form .form-control {
        font-size: 12px !important;
    }

    .SellerStepMain .personal-details-form .get-otp-text .telepara {
        top: 10px;
        right: 11px;
        font-size: 11px;
    }
}

@media (min-width: 486px) and (max-width: 576px) {
    .SellerStepMain .RSPBstep {
    margin: 0px 47px;
}
.SellerStepMain .userForm {
    padding: 10% 0% 3% 0%;
}

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        margin-top: 5%;
        justify-content: center;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }
}

@media (min-width: 577px) and (max-width: 678px) {
    .SellerStepMain .RSPBstep {
    margin: 0px 47px;
}
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }
    /* 
    .SellerStepMain .apply_now_btn .back-btn {
        padding: 5px 33px !important;
    } */

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .registerContinueMain {
        text-align: center;
        margin-bottom: 8px;
    }

    /* .SellerStepMain .userForm .apply_now_btn .tabs-btn {
        margin-left: 0 !important;
    } */
}

@media (min-width: 679px) and (max-width: 567px) {
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}

@media (min-width: 679px) and (max-width: 767px) {
    .SellerStepMain .RSPBstep {
    margin: 0px 47px;
}
    .SellerStepMain .userForm {
        padding: 15% 0% 0% 0%;
    }

    .SellerStepMain .ProgressBar .step-name {
        display: none;
    }

    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .RSPBprogressBar {
        top: 12px;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-row {
        margin-bottom: 15%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
        margin-top: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .personal-details-form .details-form-holder {
        padding: 2% 2% 40% 2%;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .SellerStepMain .JpJobseekerEditProfile .update-profile-section {
        margin-top: 5%;
    }

    .SellerStepMain .userForm .personal-details-form .form-header-holder .heading-holder h6 {
        font-size: 15px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .Languages-tick-holder {
        justify-content: center;
    }

    .SellerStepMain .ProgressBar .step {
        font-size: 13px;
        height: 30px;
        width: 30px;
    }

    .SellerStepMain .personal-details-form .details-form-holder .personal-status-buttons .status-buttons {
        font-size: 13px;
        margin-bottom: 5%;
    }

    .SellerStepMain .ProgressBar .step-name {
        bottom: -30px;
        font-size: 10px;
        line-height: 12px;
    }

    .SellerStepMain .userForm .SaveAndNext {
        bottom: 2%;
    }

    .SellerStepMain .personal-details-form .details-form-holder .select-to-holder {
        margin-bottom: 5%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .SellerStepMain .userForm {
    padding: 9% 0% 10% 0%;
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
    .SellerStepMain .RSPBprogressBar {
        top: 30px;
        width: 100%;
    }

    /* .SellerStepMain .userForm {
      padding: 25% 0% 0% 0%;
    } */
}
