/* OilCategory Css Started */


.OilCategory .text {
    position: relative;
    text-align: center;
    color: #2b7d38;
    padding: 0 20px;
    font-weight: 500;
}
.OilCategory  button.btn.btn-reverse.btn-arrow {
    background-color: white;
    padding: 9px 20px;
    color: black;
}
.OilCategory  button.btn.btn-reverse.btn-arrow:hover {
    background-color: #24642e;
    color: white;
    padding: 9px 27px;
}
.OilCategory p.text::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 11px;
    background-color: #24642e;
    bottom: 10px;
    margin-left: 12px;
}
.OilCategory p.text::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 11px;
    background-color: #24642e;
    bottom: 10px;
    margin-left: -23px;
}

.OilCategory .swiper {
    margin-top: 5%;
    padding-bottom: 6%;
}
.OilCategory .mid-title {
    text-align: center;
}
.OilCategory .swiper {
    width: 100%;
    height: 100%;
}

.OilCategory .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.OilCategory .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.OilCategory .Oil-Main-section .categories img.oil-image {
    height: 100%;
    width: 100%;
    transition: transform 0.2s ease-in-out;
}
.OilCategory .Oil-Main-section:hover .categories img.oil-image {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}
.OilCategory .Oil-Main-section .categories {
    border-radius: 10px;
    overflow: hidden;
}
.OilCategory .Oil-Main-section {
    position: relative;
}
.OilCategory .Oil-Main-section .oils-content {
    position: absolute;
    bottom: 26px;
    left: 0px;
    right: 0px;
}
/* .OilCategory .Oil-Main-section .oils-content .oil-background {
    background-color: white;
    padding: 14px 6px;
    margin: 0px 21px;
    border-radius: 8px;
} */
/* .OilCategory .Oil-Main-section .oils-content .oil-background:hover {
    background-color: #24642e;
    color: white;
} */
.OilCategory .Oil-Main-section .oils-content .oil-background p.text {
    margin-bottom: 0px;
    color: black;
    font-weight: bold;
    font-size: 15px;
    transition: color 0.3s ease, text-align 0.3s ease;
    /* text-align: end; */
}

.OilCategory .Oil-Main-section .oils-content .oil-background .icon {
    color: white;
}
section.OilCategory {
    margin-top: 5%;
    margin-bottom: 4%;
}
.OilCategory .Oil-Main-section .oils-content .oil-background .icon:hover {
    margin-left: 0px;
}
.OilCategory .Oil-Main-section .oils-content .oil-background .btn .button {
    color: white;
    border: none;
    font-size: 1rem;
    /* letter-spacing: 1.5px; */
    text-transform: uppercase;
    padding: 0px;
    transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1), background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    min-width: 100%;
    outline: none;
    vertical-align: middle;
    text-align: center;
    position: relative;
    overflow: hidden;
}
.OilCategory .swiper-pagination-bullet {
    height: 6px !important;
    border-radius: 4px !important;
    width: 15px !important;
    border-radius: 10px !important;
    /* opacity: 0.51; */
    background-color: #24642e;
}

.OilCategory span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 6px !important;
    border-radius: 10px !important;
    width: 26px !important;
    /* opacity: 0.51 !important; */
    background-color: #24642e !important;
}
@media (min-width: 400px) {
    .btn,
    button {
        width: auto;
    }
}

@media (min-width: 800px) {
    .btn,
    button {
        font-size: 1.1rem;
        padding: 1.6rem 2.8rem;
    }
}

.OilCategory .Oil-Main-section .oils-content .oil-background .btn:hover {
    color: #fff;
}

.OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow {
    position: relative;
    transition: background-color 300ms ease-out;
}
.OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
    font-weight: bold;
    font-size: 15px;
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
    color: black;
}
.OilCategory .Oil-Main-section .oils-content .oil-background:hover .btn-arrow span {
    color: white;
}
.OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow:hover span {
    transform: translate3d(-1rem, 0, 0);
}

.OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow .icon {
    position: absolute;
    width: 1.1em;
    right: 0px;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
}

.OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow:hover .icon {
    opacity: 1;
    right: -2rem;
}

.OilCategory .Oil-Main-section .oils-content .oil-background .btn-reverse {
    background: darken(#f4efef, 5%);
}

.OilCategory .Oil-Main-section .oils-content .oil-background .text {
    color: #000;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    transition: color 0.1s ease-in;
}

.OilCategory .Oil-Main-section .oils-content .oil-background .text .icon {
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.OilCategory .Oil-Main-section .oils-content .oil-background .text:hover .icon {
    transform: translateX(0.5rem);
}
/* OilCategory Css End */







/* ************************Media Query started *****************************/

@media (min-width:0px) and (max-width:320px){
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
       font-size: 10px;
    }
    section.OilCategory {
        margin-top: 11%;
        margin-bottom: 8%;
    }
    .OilCategory .swiper {
      padding-bottom: 14%;
    }
    .OilCategory .swiper {
     
        padding-bottom: 9%;
    }
}

@media (min-width:321px) and (max-width:485px){
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
       font-size: 10px;
    }
    section.OilCategory {
        margin-top: 11%;
        margin-bottom: 8%;
    }
    .OilCategory .swiper {
       padding-bottom: 9%;
    }
}
@media (min-width:486px) and (max-width:575px){
    section.OilCategory {
        margin-top: 6% !important;
        margin-bottom: 8%;

    }
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
        font-size: 10px;
     }
 
}
@media (min-width:576px) and (max-width:767px){
    section.OilCategory {
        margin-top: 6% !important;
        margin-bottom: 8%;

    }
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
        font-size: 10px;
     }
     .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
       
        font-size: 12px;
        
    }
 
}
@media (min-width:768px) and (max-width:991px){
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
     
        font-size: 12px;
        
    }
}
@media (min-width:992px) and (max-width:1199px){
    .OilCategory .Oil-Main-section .oils-content .oil-background .btn-arrow span {
        font-size: 14px;
     }
}