/* *********.Dignitaries  css started ************/

.Dignitaries .digitiry_div .ourdigi {
    padding: 65px 0px;
}
.Dignitaries .digitiry_div .ourtexts {
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #222222;
    text-transform: capitalize;
}

.Dignitaries .digitiry_div .bgrcolrr {
    background: #f6fff8 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 20px;
    opacity: 1;
    height: 400px;
}

.Dignitaries .digitiry_div .ourimgdiv {
    position: relative;
    width: 100%;
    height: 303px;
}
.Dignitaries .digitiry_div .ourimg {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.Dignitaries .digitiry_div .cardswipe {
    position: relative;
    margin-top: 40px;
    transition: margin 0.4s ease;
}
.Dignitaries .digitiry_div .cardswipe:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    margin-top: 0;
}
.Dignitaries .digitiry_div .cardswipe:hover .ficnn {
    color: #ab755c;
}
.Dignitaries .digitiry_div .ficnn {
    color: #000;
    font-size: 25px;
}
.Dignitaries .digitiry_div .text_divs {
    position: relative;
    z-index: 99;
    padding: 10px 21px;
    margin-top: -66px;
    transition: margin 0.4s ease;
}
.Dignitaries .digitiry_div .cardswipe:hover .text_divs {
    margin: 0px;
}
.Dignitaries .digitiry_div .texttext {
    display: none;
    font: normal normal normal 12px/19px Nunito Sans;
    letter-spacing: 0px;
    color: #222222;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    transition: display 0.4s ease;
}
.Dignitaries {
    margin-top: 8%;
    margin-bottom: 5%;
}
.Dignitaries .digitiry_div .cardswipe:hover .texttext {
    display: block;
}
.Dignitaries .swiper {
    padding-bottom: 2%;
}
.Dignitaries .digitiry_div .cardswipe:hover .text_divs .textplace {
    color: #222222;
}
.Dignitaries .digitiry_div .cardswipe:hover .textname {
    color: #000;
}
.Dignitaries .digitiry_div .textplace {
    font: normal normal bold 16px/22px Nunito Sans;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}
.Dignitaries .digitiry_div .textname {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #ffffff;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.Dignitaries .digitiry_div .digimainnn {
    position: relative;
}
.Dignitaries .digitiry_div .swippingg {
    position: absolute;
    top: -20px;
    width: 100%;
}
.Dignitaries p.price-text {
    color: white;
    font-weight: bold;
}
.Dignitaries .digitiry_div .cardswipe .p.price-text {
    color: white !important;
}
.Dignitaries .digitiry_div .cardswipe .mini-text-p {
    color: white;
}
.Dignitaries .digitiry_div .cardswipe:hover .price-text {
    color: rgb(9, 9, 9) !important;
}
.Dignitaries .digitiry_div .cardswipe:hover .mini-text-p {
    color: rgb(5, 5, 5);
}
.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 126px;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
    transition: opacity 0.3s ease;
}
.ourimgdiv:hover .overlay {
    opacity: 0;
}
/* ********************************Media Query started *******************************/

@media (min-width: 0px) and (max-width: 485px) {
    .Dignitaries .digitiry_div .ourtexts {
        font-size: 32px;
        padding-top: 20px;
    }
    .Dignitaries .digitiry_div .ourdigi {
        padding: 46px 0px;
    }
    .Dignitaries .digitiry_div .text_divs {
        margin-top: -56px;
    }
    
}

@media (min-width: 486px) and (max-width: 767px) {
    .Dignitaries .digitiry_div .ourtexts {
        font-size: 37px;
        padding-top: 20px;
    }
    .Dignitaries .digitiry_div .ourdigi {
        padding: 55px 0px;
    }
    .Dignitaries .digitiry_div .ourimgdiv {
        aspect-ratio: 3/3;
        height: 100%;
     }
    .Dignitaries .digitiry_div .bgrcolrr {
        height: 281px;
    }
    .Dignitaries {
     
        margin-bottom: 8%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Dignitaries .digitiry_div .ourtexts {
        font-size: 41px;
        padding-top: 20px;
    }
    .Dignitaries .digitiry_div .ourdigi {
        padding: 55px 0px;
    }
    .Dignitaries .digitiry_div .ourimgdiv {
        height: 100%;
    }
    .Dignitaries .digitiry_div .ourimgdiv {
        aspect-ratio: 3/3;
        height: 100%;
     }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Dignitaries .digitiry_div .ourtexts {
        font-size: 44px;
    }
    .Dignitaries .digitiry_div .ourimgdiv {
       aspect-ratio: 3/3;
       height: 100%;
    }

}
