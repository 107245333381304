.Terms_And_Condition_Banner .mini-text {
    color: #2E2E2E;
   
  }
  .Terms_And_Condition_Banner  .sub-text {
   color: #2E2E2E;
}
.Terms-Condition .Privacy_Policy_Content_holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 33px 62px;
 
 
}

.Privacy_Policy_Content button.decline {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 6px 11px;
  font-size: 14px;
  margin-right: 10px;
}
.Privacy_Policy_Content button.accept {
  color: white;
  padding: 6px 14px;
  background: #24642E 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font-size: 14px;
  border: none;
}
.Privacy_Policy_Content .Buttons {
  text-align: center;
}