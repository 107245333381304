/* AboutBanner Css started */

.AboutBanner .banner-about img.about-banner {
    height: 100%;
    width: 100%;
}

.AboutBanner .banner-about {
    position: relative;
}


.AboutBanner .banner-about .content-title .title {
    color: black;
}

/* AboutBanner Css End */



/* Who we Are Css Started */
.Who-We-Are .Image-content .Main-Img {
    width: 82%;
    height: 662px;
}

.Who-We-Are .Image-content .Main-Img img.Who {
    width: 100%;
    height: 100%;
    display: block;
}
.Who-We-Are .Image-content .Main-Img img.Who2 {
    display: none;
    width: 100%;
    height: 100%;
}

.Who-We-Are .Image-content {
    position: relative;
    top: -37px;
}

.Who-We-Are .Image-content .content-title {
    position: absolute;
    top: 132px;
    right: 45px;
}

.Who-We-Are .Content-Who .border-who {
    margin-top: 1%;
    border-left: 1px solid #999999;
    height: 86px;
    margin-left: 0px;
    margin-right: 44px;
}

.Who-We-Are .Content-Who {
    margin-top: 12%;
    display: flex;
}

.Who-We-Are .title {
    color: black;
    font-weight: 100;
}

.Who-We-Are .who-content {
    margin-left: 8%;
}

/* Who we Are Css End */




/**************************** Media Query Started ********************/



@media (min-width: 0px) and (max-width: 991px) {
    .Who-We-Are .Image-content .Main-Img {
        width: 100%;
        height: 100%;
        margin-bottom: 4%;
    }

    .Who-We-Are .Image-content .content-title {
        top: 11%;
        right: 0px;
        left: 0px;
        text-align: center;
    }
    .Who-We-Are  .mini-text-p {
       
        text-align: justify;
    }

    .Who-We-Are .Content-Who .border-who {
        display: none;
    }

    .Who-We-Are .who-content {
        margin-left: 0%;
    }

    .Who-We-Are .Content-Who {
        margin-top: 3%;
    }

    .Who-We-Are .Image-content {
        top: 7px;
    }
    .AboutBanner .banner-about .content {
        left: 2%;
        
        top: 1%;
    }
    section.AboutBanner {
        margin-top: 0px;
    }
    .Who-We-Are .Image-content .Main-Img img.Who {
        display: none;
        
    }
    .Who-We-Are .Image-content .Main-Img img.Who2 {
        display: block;
        
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Who-We-Are .Image-content .Main-Img {
        width: 82%;
        height: 537px;
    }

    .Who-We-Are .Image-content .content-title {

        right: 30px;
    }
}
@media (min-width: 0px) and (max-width:321px) {
}
